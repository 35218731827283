export const CREDIT_CALENDAR_FREE_USERS_CONFIG = {
  logoClass: 'csPlusLogo',
  title: 'Stay ahead of your credit',
  subText:
    'Access your 30-day credit calendar with Credit Sesame+ and take control of your credit factors.',
  feature1ImgClass: 'ringArrowIncreaseImg',
  feature1Text: 'Manage your <br /> credit score',
  feature2ImgClass: 'creditCardChurnImg',
  feature2Text: ' Track credit <br /> payments',
  feature3ImgClass: 'calendarImg',
  feature3Text: 'Prepare for your <br /> next credit report',
  cta2Text: 'View my credit goals',
};
export const SECOND_TAB_NAME = 'Goals';
export const PAYMENT_REPORTING_CARD = {
  title: 'Payment will be reported to the credit bureau',
  isPastTitle: 'Payment reported to the credit bureau',
  isPastFirstBullet:
    'Make the required <b>#minimumPayment minimum payment</b> by your statement due date to ensure an on-time payment history.',
  firstBullet:
    'Based on your last credit report, make the required <b>#minimumPayment minimum payment</b> by your statement due date to ensure an on-time payment history.',
  secondBullet:
    'Lowering the total balance will positively impact your overall Credit Usage grade.',
};
export const CREDIT_INQUIRY_FALL_OFF = {
  header: 'INQUIRY',
  title: 'Inquiry has been removed',
  description:
    '<b>Your #accountName inquiry was removed from your Sesame Grade</b>. This change will help your Credit Inquiry grade.',
  futureTitle: 'Inquiry is about to fall off',
  futureDescription:
    '<b>Your #accountName inquiry will fall off your Sesame Grade</b>. This change will help your Credit Inquiry grade.',
  gradesImpacted: 'INQUIRIES',
  cta: 'View credit inquiries',
};

export const CREDIT_USAGE_REPORT = {
  description:
    'In order to increase your Credit Usage grade, keep your total credit card balance below #creditCardBalance before your next credit report.',

  bullet1:
    'Pay down debt - if possible reduce your total credit usage to #potentialPercentage by making a payment or payments totaling at least #minPaymentToPotentialUsage',
  bullet2HasLink: true,
  bullet3HasLink: true,
};
