import { getCookieValue } from 'helpers/cookie.helper';
import { mktTrackingCookieName } from 'pages/SignupPage/types/marketingAttribution.constants';
import { D2P_MARKETING_PARAM_COOKIE, LOAD_D2P_DATA } from 'types/d2p.constants';
import { useFlag } from './flag.hook';
import { useHistory } from 'react-router-dom';
import { useLogger } from 'hook/error.hook';
import { useDispatch, useSelector } from 'react-redux';
import { getGetParam } from 'helpers/domHelper';
import { useEffect } from 'react';
import { getItem, setItem } from 'helpers/localstorage';
import {
  completeFreeAccountRegistrationD2P,
  postSignUpHandler as postSignUpHandlerOneClick,
} from 'pages/SignupPage/OneClickSignup/OneClickSignup.actions';
import { postSignUpHandler } from 'pages/SignupPage/actions/signup.actions';

const PLAN_CODE_ITEM = 'PLAN_CODE_D2P';

export const useD2PConfig = props => {
  const { d2p, userInfo } = useSelector(state => ({
    d2p: state?.d2p,
    userInfo: state.userInfo.userInfo,
  }));

  const planCodeFreePublicSite = getGetParam(D2P_MARKETING_PARAM_COOKIE);
  const logger = useLogger({
    name: 'd2p.hook',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { tracking, signupType } = props || {};
  const pageName = tracking?.['Page Name'];
  const directToPremiumExp = useFlag('direct_to_premium', {
    defaultConfig: {},
    forceDefaultConfigForDisabledOptimizely: true,
  });

  const postPurchaseAction = () => {
    const { isUserResurrected, extraProps, trackingAttrs } =
      d2p?.postSignUpData || {};
    if (signupType === 'oneclickSignup') {
      dispatch(
        postSignUpHandlerOneClick(userInfo, history, isUserResurrected, {
          ...trackingAttrs,
          ...d2p?.d2pTracking,
        }),
      );
    } else {
      dispatch(
        postSignUpHandler(
          history,
          userInfo,
          pageName,
          isUserResurrected,
          extraProps,
        ),
      );
    }
  };

  const handleClickTryFree = () => {
    dispatch({
      type: LOAD_D2P_DATA,
      loading: true,
    });
    dispatch(completeFreeAccountRegistrationD2P())
      .then(() => {
        dispatch({
          type: LOAD_D2P_DATA,
        });
        postPurchaseAction();
      })
      .catch(error => {
        dispatch({
          type: LOAD_D2P_DATA,
          loading: false,
        });
        logger.reportAPIError(error);
        logger.showError('Unable to complete registration', error);
      });
  };

  const getPlanCode = () => {
    const planCodeFromLocalStorage = getItem(PLAN_CODE_ITEM);
    const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
    const inboundParamsCookieObj =
      inboundParamsCookie &&
      JSON.parse(decodeURIComponent(inboundParamsCookie));
    let planCode = userInfo?.directToPremiumInfo?.planCode;
    const planCodeMarketingUrl =
      inboundParamsCookieObj?.[D2P_MARKETING_PARAM_COOKIE];
    if (!planCode) {
      planCode = planCodeMarketingUrl;
    }
    if (!planCode) {
      planCode = planCodeFromLocalStorage;
    }
    if (!planCode) {
      planCode = planCodeFreePublicSite;
    }
    if (planCodeFromLocalStorage !== planCode) {
      setItem(PLAN_CODE_ITEM, planCode);
    }
    return planCode;
  };

  useEffect(() => {
    if (d2p?.planCode) {
      dispatch({
        type: LOAD_D2P_DATA,
        loading: false,
      });
    } else {
      dispatch({
        type: LOAD_D2P_DATA,
        loading: true,
      });
    }
  }, [d2p?.planCode]);

  useEffect(() => {
    if (!directToPremiumExp?.loading) {
      if (directToPremiumExp?.isEnabled) {
        const planCode = getPlanCode();
        const planConfig = directToPremiumExp?.config?.[planCode];
        const direct2Premium = planConfig ? true : false;
        if (d2p?.planCode !== planCode || d2p?.planConfig !== planConfig) {
          dispatch({
            type: LOAD_D2P_DATA,
            planCode,
            planConfig,
            direct2Premium,
            loading: false,
          });
        }
      } else {
        dispatch({
          type: LOAD_D2P_DATA,
          loading: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directToPremiumExp?.loading, directToPremiumExp?.isEnabled]);

  useEffect(() => {
    if (d2p) {
      if (!d2p?.d2pTracking && tracking) {
        dispatch({
          type: LOAD_D2P_DATA,
          d2pTracking: tracking,
        });
      }
    }
  }, [d2p, tracking]);

  return {
    ...d2p,
    enabled: directToPremiumExp?.isEnabled,
    handleClickTryFree,
    postPurchaseAction,
  };
};
