import React from 'react';
import Badge from 'components/Badge/Badge';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import Logo from 'components/navigation/Menu/Logo';
import OpenInAppBtn from './index';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { Link } from 'components/navigation/Link/Link';
import { ReactComponent as HamburgerButton } from 'style/_assets/icons/menu.svg';
import NotificationIconSection from 'components/NotificationIconSection/NotificationIconSection';
import { PremiumPill } from 'partial/PremiumPill/PremiumPill';
import { useConfig } from 'hook/config.hook';

const GlobalHeaderWithOpenInAppBtn = props => {
  const { config } = useConfig();
  const {
    currentPath,
    eventHandler,
    isBoosterWaitlistPage,
    isPremiumUser,
    loggedIn,
    logoLink,
    isSignup,
    totalUnreadCount,
    isPlusUser,
    onMenuToggle,
    notifications,
    premiumPacakgeTitle,
  } = props;

  const onClickBell = () =>
    props.eventHandler(CLICK, {
      'Click Type': 'Global Header Notification Bell',
    });

  return (
    <FlexContainer
      className={`d-hide justify-between t-width100 items-align-center ${
        isPlusUser ? 'continue-to-app-banner-plus' : 'continue-to-app-banner'
      }`}
    >
      <FlexContainer alignItemsCenter className="m-padL16 m-hamburger ">
        <a
          href="##"
          onClick={onMenuToggle}
          data-testid="headerLoggedIn-mobile"
          aria-label="Menu"
        >
          <FlexContainer className="mobileMenuItemWrapper">
            <FlexContainer
              className={`menuDotIconSection ${
                isPlusUser ? 'menuDotPlusIconSection' : ''
              }`}
            >
              {notifications && (
                <Badge
                  type="dot"
                  className="pos-abs"
                  style={{ right: '0', top: '-4px' }}
                />
              )}
              <HamburgerButton />
            </FlexContainer>
          </FlexContainer>
        </a>
      </FlexContainer>
      <div className="left">
        <Logo
          showLogo={true}
          eventHandler={eventHandler}
          loggedIn={loggedIn}
          logoLink={logoLink}
          currentPath={currentPath}
          isBoosterWaitlistPage={isBoosterWaitlistPage}
          isPremiumUser={isPremiumUser}
          isSignup={isSignup}
          isPlusUser={isPlusUser}
        />
      </div>
      <FlexContainer className="justify-end format100 items-align-center">
        <Link
          onClickCallback={onClickBell}
          to="/alerts"
          data-testid={`header-alerts-link`}
          activeClassName="selected"
          className="pos-rel margR10"
          ariaLabel="alerts"
        >
          <FlexContainer className="justify-center format100 items-align-center">
            <NotificationIconSection
              totalUnreadCount={totalUnreadCount}
              isPlusUser={isPlusUser}
            />
          </FlexContainer>
        </Link>
        {isPremiumUser && config?.navBar?.showPremiumPill && (
          <PremiumPill
            backgroundClass="navbarPill"
            text={premiumPacakgeTitle}
          />
        )}
      </FlexContainer>
      <div className="mobile-right m-padR20">
        <OpenInAppBtn />
      </div>
    </FlexContainer>
  );
};

export default GlobalHeaderWithOpenInAppBtn;
