import { convertObjectToQueryString } from 'externals/_services/service_helpers';
import { getGetParam } from 'helpers/domHelper';
import { getCookieValue } from './cookie.helper';
import { mktTrackingCookieName } from '../pages/SignupPage/types/marketingAttribution.constants';
import { setItem } from 'helpers/localstorage';
import * as UAParser from 'ua-parser-js';

export const isLoginFromLogout = () => {
  return getGetParam('recentLogout') === 'true';
};

export const cleanParamFromUrl = (param, baseUrl, location, history) => {
  const urlParams = new URLSearchParams(location.search);
  urlParams.delete(param);
  const queryString = urlParams.toString();
  const cleanUrl =
    queryString.length > 0 ? `${baseUrl}?${queryString}` : baseUrl;
  history.replace(cleanUrl);
};

export const getPlatformQueryParam = search => {
  if (search) {
    const urlParams = new URLSearchParams(search);
    const platform = urlParams.get('platform');
    return convertObjectToQueryString({ platform });
  }
  return '';
};

export const urlHasUtmCampaignParamPremiumMarketing = () =>
  getGetParam('utm_campaign') === 'PremiumMarketing';

export const isUserFromEnrolledPremiumCampaign = () => {
  try {
    const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
    const inboundParamsCookieObj = JSON.parse(
      decodeURIComponent(inboundParamsCookie),
    );

    if (
      inboundParamsCookieObj['initial_referrer_url'].indexOf(
        'utm_campaign=PremiumMarketing',
      ) > -1
    ) {
      return true;
    } else if (urlHasUtmCampaignParamPremiumMarketing()) {
      setItem('utm_campaign', 'PremiumMarketing');
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export function getBrowserInfo() {
  const userAgent = window.navigator.userAgent;
  const ua = new UAParser.UAParser(userAgent);
  const { name: browser, version: browserVersion } = ua.getBrowser();
  const { name: operatingSystem, version: osVersion } = ua.getOS();
  const isMobile =
    /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile/i.test(
      userAgent,
    );

  return {
    appVersionString: navigator.appVersion,
    browser,
    browserVersion,
    operatingSystem,
    osVersion,
    isMobile,
  };
}
