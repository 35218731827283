import { setItem, getItem, removeItem } from 'helpers/localstorage';
import { createLogger } from 'helpers/logger';
import { isProduction } from 'helpers/env';
import { resolveBooleanToYesOrNo } from './tracking.helpers';
import { getGetParam } from 'helpers/domHelper';
import { standardEventHandler } from 'actions/eventHandler.actions';

const logger = createLogger({
  name: 'signup.helpers',
  categories: ['signup'],
});

export const persistSignupData = data => {
  setItem('oneClickSignupData', JSON.stringify(data));
};

export const getSignupData = () => {
  const signupData = getItem('oneClickSignupData');
  try {
    return JSON.parse(signupData);
  } catch (err) {
    logger.error('Error parsing one click signup 1 data', err);
  }
  return null;
};

export const goToSignup2 = (history, isDupSSN) => {
  !isDupSSN && removeItem('ocsTempPwd');
  history.replace('/signup2');
};

export const errorLogout = (history, type) => {
  removeItem('oneClickSignupData');
  history.replace(`/signupError?type=${type}`);
};

export const isTestFlow = () => {
  return !isProduction() && getItem('oneClickIpAddress');
};

export const isTestPhoneNumber = phoneNumber => {
  return !isProduction() && phoneNumber === '2001002007';
};

export const getBaseIPHeader = ipAddress => {
  if (isTestFlow()) {
    return { 'X-Forwarded-For': ipAddress };
  } else {
    return {};
  }
};

export const getIPHeader = () => {
  const ipAddress = getItem('oneClickIpAddress');
  return getBaseIPHeader(ipAddress);
};

export const getIdentityIPHeader = () => {
  const ipAddress =
    getItem('ocsIdentityIpAddress') || getItem('oneClickIpAddress');
  return getBaseIPHeader(ipAddress);
};

export const getCommonTrackingAttributes = (signup1, signup2, currentFlow) => {
  return {
    MobileAuth_success: resolveBooleanToYesOrNo(signup1?.isPrefillPhone),
    Prefilled_eligible: resolveBooleanToYesOrNo(signup1?.isPrefillEligible),
    Prefill_phone: resolveBooleanToYesOrNo(signup1?.isPrefillPhone),
    Prefill_name: resolveBooleanToYesOrNo(signup2?.isPrefillName),
    Prefill_address: resolveBooleanToYesOrNo(signup2?.isPrefillAddress),
    Prefill_DOB: resolveBooleanToYesOrNo(signup2?.isPrefillDob),
    phone_edited: resolveBooleanToYesOrNo(signup2?.isPhoneEdit),
    name_edited: resolveBooleanToYesOrNo(signup2?.isNameEdit),
    address_edited: resolveBooleanToYesOrNo(signup2?.isAddressEdit),
    DOB_edited: resolveBooleanToYesOrNo(signup2?.isDobEdit),
    current_signup_flow: currentFlow,
  };
};

export const getCommonTrackingD2PAttributes = props => {
  const { d2pConfig } = props || {};
  const { planCode, planConfig, d2pTracking } = d2pConfig || {};
  let attributesD2P = {
    'Sign Up Flow Type': 'free',
  };
  if (planCode && planConfig) {
    attributesD2P = {
      'Plan Type': planCode,
      'Sign Up Flow Type': 'd2p',
      ...d2pTracking,
    };
  }
  return attributesD2P;
};

export const getOneClickSignupTracking = () => {
  const trackingData = getItem('oneClickSignupTracking');
  try {
    return JSON.parse(trackingData);
  } catch (err) {
    logger.error('Error parsing one click signup 1 tracking data', err);
  }
  return null;
};

export const handleIpAddress = () => {
  const ipAddress = getGetParam('ip');
  const identityIpAddress = getGetParam('identityIp');
  if (ipAddress) {
    setItem('oneClickIpAddress', ipAddress);
  }
  if (identityIpAddress) {
    setItem('ocsIdentityIpAddress', identityIpAddress);
  }
};

export const sendSMSVerificationEvent =
  (status, trackingAttrs, verificationType) => dispatch => {
    dispatch(
      standardEventHandler(
        'SMS phone verification',
        Object.assign(
          {
            SMS_phone_verification: status,
            verificationType: verificationType,
          },
          trackingAttrs,
        ),
      ),
    );
  };

export const getPixelUrl = () => {
  return `https://${window.location.host}/oneClickSignup/pixel`;
};

export const isSsnMismatchError = err => {
  return err?.errorCode === 'OCPRFL_1051';
};

export const isCreditProfileError = err => {
  return err?.errorCode === 'CP_1013' || err?.errorCode === 'SU_1040';
};
