import { cleanPopupPaths } from 'helpers/path.helper';
import { useFlag } from 'hook/flag.hook';
import { isVariationLoadedAndEnabled } from 'helpers/optimizely.helper';
import { CLICK } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';
import { SUBSCRIPTION_VERTICAL } from 'types/mrph.vertical.constants';
import { MAIN_NAV } from 'externals/_tracking/types/clickEventTypes.constants';
import {
  getDunningPeriodDate,
  isPremiumPaymentPending,
} from 'helpers/userHelper';
import { getSimplifiedDate } from 'helpers/dateHelper';

export const usePremiumReactivatePendingCancellation = props => {
  const { eventHandler, history, userInfo } = props;

  const expPremiumReactivatePendingCancellation = useFlag(
    'premiumreactivatecancelpending_web',
  );
  const isPremiumReactivatePendingCancellationUser =
    isVariationLoadedAndEnabled(expPremiumReactivatePendingCancellation);
  const premiumReactivatePendingCancellationConfig =
    expPremiumReactivatePendingCancellation?.config;

  const daysLeft = userInfo?.premiumPlanInfo?.daysToReactivateSubscription;
  const cancelDate = isPremiumPaymentPending(userInfo)
    ? getDunningPeriodDate(userInfo)
    : getSimplifiedDate(userInfo?.premiumPlanInfo?.cancelDate);

  const orderConsentWebFlag = useFlag('orderconsent_web');
  const isOrderConsentFlagEnabled = orderConsentWebFlag?.config?.isEnabled;

  const openPremiumReactivatePendingCancellationModal = () => {
    const newPath = cleanPopupPaths(history.location.pathname);
    eventHandler(CLICK, {
      'Click Type': MAIN_NAV,
      'Nav Item': 'Reactivate',
      Vertical: SUBSCRIPTION_VERTICAL,
    });

    history.push(`${newPath}/premiumReactivatePendingCancellation`);
  };
  const onClickProfilePageCta = () => {
    openPremiumReactivatePendingCancellationModal();
  };

  return {
    openPremiumReactivatePendingCancellationModal,
    isPremiumReactivatePendingCancellationUser,
    premiumReactivatePendingCancellationConfig,
    daysLeft,
    cancelDate,
    onClickProfilePageCta,
    orderConsentWebFlag,
    isOrderConsentFlagEnabled,
  };
};
