export const campaignIdAssociatedCopies = {
  credit_cards: 'credit_cards',
  credit_score: 'credit_score',
  loan: 'loan',
  consumer_affairs: 'consumer_affairs',
  '3_bureau': 'consumer_affairs',
  myfreecreditscore: 'myfreecreditscore',
  premium: 'premium',
  cash: 'cash',
  cash_builder: 'cash_builder',
  mortgage: 'mortgage',
  insurance: 'insurance',
  credit_builder: 'credit_builder',
  credit_monitoring: 'credit_monitoring',
  credit_monitoringv2: 'credit_monitoringv2',
  home_auto_loan: 'home_auto_loan',
  credit_report: 'credit_report',
};

export const E2E_MARKETING_PARAM_COOKIE = 'ReferringURLSubId5';
