import { wrapService } from 'actions/service_wrapper.actions';
import {
  DUPE_SSN_FLOW,
  ocsFlowTracking,
  oneClickSignupConstants as signupConstants,
  optEventsSignupSubmit,
} from './OneClickSignup.constants';
import { reportOptimizely } from 'externals/_tracking/index';
import {
  initDupeSsnLoading,
  reportSignup2Submit,
  setSignup2Step,
  startLoading,
  stopLoading,
} from './OneClickSignup.actions';
import { patchUser, userService } from 'externals/_services/user.service';
import { getSlapiApiKey } from 'helpers/env';
import { dupeSSN, getFreshUserInfo } from '../actions/signup.actions';
import moment from 'moment';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from 'types/app.constants';
import {
  deleteCookieValueForCSDomain,
  getCookieValue,
} from 'helpers/cookie.helper';
import { getItem, setItem, setUserLogoutTime } from 'helpers/localstorage';
import {
  initUserTracking,
  OPT_SIGNUP_ACCOUNT_CREATED,
} from '../signup.tracking';
import {
  ACCT_CREATION,
  ADDRESS,
  PASSWORD,
  PHONE_NUMBER,
  SMARTY_STREETS_VALIDATION,
  SSN,
  ssnExistsData,
  USER_VALIDATION,
  ONE_CLICK_SIGNUP_KBA_ENTER_NAME_AND_DOB,
  ONE_CLICK_SIGNUP_KBA_ENTER_HOME_ADDRESS,
  ONE_CLICK_SIGNUP_KBA_REVIEW_INFORMATION,
  UPDATE_USER_INFO,
  GO_TO_NEXT_PAGE,
} from '../types/signup.constants';
import { reportPageClicks } from 'pages/SignupPage/signup.tracking';
import { getSessionRole, USER_ROLE } from 'helpers/session';
import { createLogger } from 'helpers/logger';
import { mixpanelEvents } from 'externals/_tracking/mixpanel/Mixpanel.events.config';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import {
  getSignupData,
  goToSignup2,
  persistSignupData,
} from 'helpers/oneClickSignup.helpers';
import {
  OCS_ADDRESS_STEP_FALLBACK_FORM,
  OCS_EMAIL_STEP_FALLBACK_FORM,
  OCS_PASSWORD_STEP_FALLBACK_FORM,
} from 'types/mrph.pages.constants';
import { standardEventHandler } from 'actions/eventHandler.actions';
import { createDelayedPromise } from 'helpers/promiseHelper';

const ocs1_createUser = wrapService(userService.register, {
  name: 'ocs1_createUser',
});
const ocs1_loginUser = wrapService(userService.login, {
  name: 'ocs1_loginUser',
});
const ocs1_updateUserAddress = wrapService(userService.updateUserAddress, {
  name: 'ocs1_updateUserAddress',
});
const ocs1_patchUser = wrapService(patchUser, {
  name: 'ocs1_patchUser',
});
const ocs1_updateUserSSN = wrapService(userService.updateUserSSN, {
  name: 'ocs1_updateUserSSN',
});
const ocs1_updateUser = wrapService(userService.update, {
  name: 'ocs1_updateUser',
});
const ocs1_updateUserPassword = wrapService(userService.updateUserPassword, {
  name: 'ocs1_updateUserPassword',
});

const logger = createLogger({
  name: 'OneClickTUSignup.actions',
  categories: ['signup'],
  includeVisitor: true,
});

const createUser =
  (formData, isEditAllForm, trackingAttrs, expVariationsConfig) =>
  async (dispatch, getState) => {
    let payload = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      apiKey: getSlapiApiKey(),
      verifyEmail: true,
    };
    if (expVariationsConfig) {
      const { variation2, lte_screen_and_fallback_flow_simplified } =
        expVariationsConfig;

      if (lte_screen_and_fallback_flow_simplified) {
        payload = {
          email: formData.email,
          contactPhoneNumber: formData.phoneNumber,
          firstName: formData.firstName,
          lastName: formData.lastName,
          birthDate: formData.birthDate,
          apiKey: getSlapiApiKey(),
          verifyEmail: true,
        };
      } else if (variation2) {
        payload = {
          email: formData.email,
          contactPhoneNumber: formData.phoneNumber,
          apiKey: getSlapiApiKey(),
          verifyEmail: true,
        };
      }
    }

    if (isEditAllForm) {
      payload.contactPhoneNumber = formData.phoneNumber;
      payload.birthDate = formData.birthDate;
    }

    try {
      const createUserRes = await dispatch(ocs1_createUser(payload));
      if (createUserRes.errorCode === 'SU_1002') {
        throw createUserRes;
      }
      setTempPassword(createUserRes.password);
      await dispatch(userLogin(createUserRes, trackingAttrs));
    } catch (err) {
      dispatch(reportTUError(err, ACCT_CREATION));
      throw err;
    }
  };

const updateUser =
  (formData, isEditAllForm, expVariationsConfig) =>
  async (dispatch, getState) => {
    let payload = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      verifyEmail: true,
    };

    if (expVariationsConfig?.lte_screen_and_fallback_flow_simplified) {
      payload = {
        email: formData.email,
        contactPhoneNumber: formData.phoneNumber,
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthDate: formData.birthDate,
      };
    }
    if (isEditAllForm) {
      payload.contactPhoneNumber = formData.phoneNumber;
      payload.birthDate = formData.birthDate;
    }

    try {
      await dispatch(ocs1_updateUser(payload));
    } catch (err) {
      dispatch(reportTUError(err, ACCT_CREATION));
      throw err;
    }
  };

const createOrUpdateUser =
  (formData, isEditAllForm, trackingAttrs, expVariationsConfig) =>
  async (dispatch, getState) => {
    if (getSessionRole() !== USER_ROLE) {
      return await dispatch(
        createUser(formData, isEditAllForm, trackingAttrs, expVariationsConfig),
      );
    } else {
      return await dispatch(
        updateUser(formData, isEditAllForm, expVariationsConfig),
      );
    }
  };

const userLogin =
  (registrationRes, trackingAttrs) => async (dispatch, getState) => {
    dispatch({
      type: LOGIN_REQUEST,
      email: registrationRes.email,
    });
    try {
      const auth = await dispatch(
        ocs1_loginUser(
          registrationRes.email,
          registrationRes.password,
          null,
          getCookieValue('cs-api-sessid'),
        ),
      );
      if (getCookieValue('cs-api-sessid')) {
        deleteCookieValueForCSDomain('cs-api-sessid');
      }
      const t = moment();
      t.add(auth.expiresSeconds, 's');
      setUserLogoutTime(t.format());
      dispatch({ type: LOGIN_SUCCESS, user: { ...auth } });

      //start tracking
      initUserTracking(registrationRes, auth.userId, auth.sessionIdentifier);
      dispatch(
        standardEventHandler(
          mixpanelEvents.SIGNUP_ACCOUNT_CREATED,
          Object.assign(
            {
              'Sendex Score': registrationRes.sendexScore,
              forceSendUnified: true,
            },
            trackingAttrs,
          ),
          true,
        ),
      );
      reportOptimizely(OPT_SIGNUP_ACCOUNT_CREATED);
      return auth;
    } catch (err) {
      dispatch({ type: LOGIN_FAILURE });
      throw err;
    }
  };

const updateAddress = formData => async (dispatch, getState) => {
  const validationType = getState().oneClickSignup.addressValidated
    ? USER_VALIDATION
    : SMARTY_STREETS_VALIDATION;

  let addressReq = { primaryAddress: formData.address };
  if (formData.addressSecondary) {
    addressReq = {
      ...addressReq,
      secondaryAddress: formData.addressSecondary,
    };
  }

  try {
    await dispatch(ocs1_updateUserAddress(addressReq, validationType));
  } catch (err) {
    let addressValidated =
      err.errorCode === 'SU_1017' || err.errorCode === 'SU_1066';
    dispatch({
      type: signupConstants.UPDATE_ADDRESS_FAIL,
      addressValidated: addressValidated,
    });
    dispatch(reportTUError(err, ADDRESS));
    throw err;
  }
};

const updatePhoneNumber =
  (formData, isEditAllForm) => async (dispatch, getState) => {
    if (isEditAllForm) {
      return;
    }

    try {
      await dispatch(
        ocs1_patchUser({
          contactPhoneNumber: formData.phoneNumber,
        }),
      );
    } catch (err) {
      dispatch(reportTUError(err, PHONE_NUMBER));
      throw err;
    }
  };

const updateSsn = (formData, isFallbackForm) => async (dispatch, getState) => {
  try {
    await dispatch(ocs1_updateUserSSN(formData.ssn));
  } catch (err) {
    dispatch(reportTUError(err, SSN));
    throw err;
  }
};

const updateDob = formData => async (dispatch, getState) => {
  try {
    await dispatch(
      ocs1_patchUser({
        birthDate: formData.birthDate,
      }),
    );
  } catch (err) {
    dispatch(reportTUError(err, PHONE_NUMBER));
    throw err;
  }
};

export const updatePassword = formData => async (dispatch, getState) => {
  const tempPassword = getTempPassword();
  if (tempPassword === formData.password) {
    return;
  }

  try {
    await dispatch(
      ocs1_updateUserPassword({
        currentPassword: tempPassword,
        newPassword: formData.password,
        confirmPassword: formData.password,
      }),
    );
    setTempPassword(formData.password);
  } catch (err) {
    dispatch(reportTUError(err, PASSWORD));
    throw err;
  }
};

export const submitSignup2TUForm =
  (formData, isEditAllForm, trackingAttrs) => async (dispatch, getState) => {
    dispatch(startLoading());
    dispatch({
      type: signupConstants.CLEAR_TU_ERROR,
    });
    try {
      await dispatch(
        createOrUpdateUser(formData, isEditAllForm, trackingAttrs),
      );
      await dispatch(updateAddress(formData));
      await dispatch(updatePhoneNumber(formData, isEditAllForm));
      await dispatch(updatePassword(formData));
      await dispatch(updateSsn(formData));
      await dispatch(getFreshUserInfo());
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitSignup2TUForm.OneClickTUSignup.actions',
      );
      throw err;
    } finally {
      dispatch(stopLoading());
    }
  };

export const submitEmailFallbackForm =
  (formData, trackingAttrs) => async (dispatch, getState) => {
    dispatch(startLoading());
    dispatch({
      type: signupConstants.CLEAR_TU_ERROR,
    });
    try {
      await dispatch(createOrUpdateUser(formData, false, trackingAttrs));
      await dispatch(updatePhoneNumber(formData, false));
      await dispatch(getFreshUserInfo());

      dispatch(
        reportFallbackSubmit(OCS_EMAIL_STEP_FALLBACK_FORM, trackingAttrs),
      );
      dispatch(updateStep(OCS_ADDRESS_STEP_FALLBACK_FORM, formData?.ssn));
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitEmailFallbackForm.OneClickTUSignup.actions',
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const submitPhoneEmailFallbackForm =
  (formData, trackingAttrs, expVariationsConfig) =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    dispatch({
      type: signupConstants.CLEAR_TU_ERROR,
    });
    try {
      await dispatch(
        createOrUpdateUser(formData, false, trackingAttrs, expVariationsConfig),
      );
      await dispatch(getFreshUserInfo());

      if (expVariationsConfig?.lte_screen_and_fallback_flow_simplified) {
        dispatch(
          reportPageClicks(
            ONE_CLICK_SIGNUP_KBA_ENTER_NAME_AND_DOB,
            GO_TO_NEXT_PAGE,
          ),
        );
      } else {
        dispatch(
          reportFallbackSubmit(OCS_EMAIL_STEP_FALLBACK_FORM, trackingAttrs),
        );
      }
      dispatch(updateStep(OCS_ADDRESS_STEP_FALLBACK_FORM, formData?.ssn));
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitPhoneEmailFallbackForm.OneClickTUSignup.actions',
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const submitAddressFallbackForm =
  (formData, trackingAttrs) => async (dispatch, getState) => {
    dispatch(startLoading());
    dispatch({
      type: signupConstants.CLEAR_TU_ERROR,
    });
    try {
      await dispatch(updateAddress(formData));
      await dispatch(updateDob(formData));
      await dispatch(updateSsn(formData, true));
      await dispatch(getFreshUserInfo());

      dispatch(
        reportFallbackSubmit(OCS_ADDRESS_STEP_FALLBACK_FORM, trackingAttrs),
      );
      dispatch(updateStep(OCS_PASSWORD_STEP_FALLBACK_FORM));
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitAddressFallbackForm.OneClickTUSignup.actions',
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const submitFullnameAddressSsnFallbackForm =
  (formData, trackingAttrs, expVariationsConfig) =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    dispatch({
      type: signupConstants.CLEAR_TU_ERROR,
    });
    try {
      await dispatch(createOrUpdateUser(formData, true, trackingAttrs));
      await dispatch(updateAddress(formData));

      if (!expVariationsConfig?.lte_screen_and_fallback_flow_simplified) {
        await dispatch(updateSsn(formData, true));
      }

      await dispatch(getFreshUserInfo());

      if (expVariationsConfig?.lte_screen_and_fallback_flow_simplified) {
        dispatch(
          reportPageClicks(
            ONE_CLICK_SIGNUP_KBA_ENTER_HOME_ADDRESS,
            GO_TO_NEXT_PAGE,
          ),
        );
      } else {
        dispatch(
          reportFallbackSubmit(OCS_ADDRESS_STEP_FALLBACK_FORM, trackingAttrs),
        );
      }
      dispatch(updateStep(OCS_PASSWORD_STEP_FALLBACK_FORM));
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitFullnameAddressSsnFallbackForm.OneClickTUSignup.actions',
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const submitFullnameAddressSsnPasswordFallbackForm =
  (formData, trackingAttrs, history, setState, expVariationsConfig) =>
  async dispatch => {
    dispatch(startLoading());
    dispatch({
      type: signupConstants.CLEAR_TU_ERROR,
    });
    try {
      await dispatch(
        createOrUpdateUser(formData, true, trackingAttrs, expVariationsConfig),
      );
      await dispatch(updateAddress(formData));
      await dispatch(updateSsn(formData, true));
      await dispatch(updatePassword(formData));
      await dispatch(getFreshUserInfo());

      if (expVariationsConfig?.lte_screen_and_fallback_flow_simplified) {
        dispatch(
          reportPageClicks(
            ONE_CLICK_SIGNUP_KBA_REVIEW_INFORMATION,
            UPDATE_USER_INFO,
          ),
        );
      } else {
        dispatch(
          reportFallbackSubmit(OCS_PASSWORD_STEP_FALLBACK_FORM, trackingAttrs),
        );
      }
      setItem('oneClickSignupTracking', JSON.stringify(trackingAttrs));
      goToSignup2(history);
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitFullnameAddressSsnPasswordFallbackForm.OneClickTUSignup.actions',
      );
      setState({
        editInfoPanelAlertError: err.message,
      });
    } finally {
      dispatch(stopLoading());
    }
  };

export const submitPasswordFallbackForm =
  (
    oneClickSignupState,
    formData,
    trackingAttrs,
    history,
    setState,
    expVariationsConfig,
  ) =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    try {
      const isDupSSN = oneClickSignupState?.errorTU?.errorCode === 'SU_1057';
      if (expVariationsConfig?.lte_screen_and_fallback_flow_simplified) {
        await dispatch(updateSsn(formData, true));
      }

      if (isDupSSN) {
        dispatch(
          dupeSSN({
            ...oneClickSignupState?.errorTU,
            password: formData.password,
          }),
        );
      } else {
        await dispatch(updatePassword(formData));
        await dispatch(getFreshUserInfo());

        dispatch(
          reportFallbackSubmit(OCS_PASSWORD_STEP_FALLBACK_FORM, trackingAttrs),
        );
        setItem('oneClickSignupTracking', JSON.stringify(trackingAttrs));

        dispatch({
          type: signupConstants.CLEAR_TU_ERROR,
        });
      }
      goToSignup2(history, isDupSSN);
    } catch (err) {
      logger.reportAPIError(
        err,
        'submitPasswordFallbackForm.OneClickTUSignup.actions',
      );
      setState({
        editInfoPanelAlertError: err.message,
        showEditInfoPanel: true,
      });
    } finally {
      dispatch(stopLoading());
    }
  };

export const reportFallbackSubmit = (step, trackingAttrs) => dispatch => {
  reportOptimizely(optEventsSignupSubmit[step]);
  dispatch(
    standardEventHandler(
      CLICK,
      Object.assign(
        {
          'Click Type': 'Submit Form',
          click_title: 'Continue',
        },
        trackingAttrs,
      ),
    ),
  );
};

export const handleDupeSsnFlow =
  (err, formData, trackingAttrs, step, history, isNoPrefillForm) =>
  async dispatch => {
    const dupData = { ...err };
    setItem(ssnExistsData, JSON.stringify(dupData));

    if (isNoPrefillForm) {
      dispatch(standardEventHandler(ocsFlowTracking[DUPE_SSN_FLOW]));
      reportFallbackSubmit(OCS_ADDRESS_STEP_FALLBACK_FORM, trackingAttrs);
      dispatch(updateStep(OCS_PASSWORD_STEP_FALLBACK_FORM));
    } else {
      dispatch(initDupeSsnLoading(formData));
      await Promise.all([
        dispatch(getFreshUserInfo()),
        createDelayedPromise(null, 2500),
      ]);
      dispatch(reportSignup2Submit(step, trackingAttrs));
      goToSignup2(history);
    }
  };

export const setTempPassword = password => {
  setItem('ocsTempPwd', password);
};

export const getTempPassword = () => {
  return getItem('ocsTempPwd');
};

export const updateStep = (step, ssn) => dispatch => {
  const signupData = getSignupData();
  signupData.signup2.step = step;
  if (ssn) {
    signupData.signup2.fallbackFormSsn = ssn;
  }
  persistSignupData(signupData);
  dispatch(setSignup2Step(step));
};

export const reportTUError = (error, errorSection) => ({
  type: signupConstants.REPORT_TU_ERROR,
  errorTU: error,
  errorSectionTU: errorSection,
});
