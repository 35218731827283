import { getTrustEvKey } from 'helpers/env';
import { createLogger } from 'helpers/logger';

const logger = createLogger({
  name: 'trustev',
});

export const getTUTrustEvSessionId = () => {
  return new Promise((resolve, reject) => {
    let resolved = false;
    if (global.TrustevV2) {
      global.TrustevV2.Init(
        getTrustEvKey(),
        sessionId => {
          if (!resolved) resolve(sessionId);
          resolved = true;
        },
        true,
      );
      setTimeout(() => {
        if (!resolved) {
          logger.errorException(
            'TU TrustEv timed out before receiving tuSessionId.',
          );
          resolved = true;
          reject(null);
        }
      }, 6000);
    } else {
      logger.errorException('Unable to load TU TrustEv script.');
      resolved = true;
      reject(null);
    }
  });
};
