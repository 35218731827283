import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { visitorReportPageView } from 'externals/_tracking/mixpanel/mixpanel.track';
import { logout } from 'actions/login.actions';
import { SignupErrorPrimaryView } from './components/SignupErrorPrimaryView';
import { getGetParam } from 'helpers/domHelper';
import { withRouter } from 'react-router-dom';
import {
  errorPageNameMap,
  ACCOUNT_LOCKOUT,
} from './types/signupError.constants';
import PageLoadingMask from 'components/masks/PageLoadingMask';
import { useEventHandler } from 'hook/event.hook';
import {
  CLICK,
  VIEW_PAGE,
} from 'externals/_tracking/types/eventTypes.constants';
import { useConfigLoader } from 'hook/config.hook';
import { useD2PConfig } from 'hook/d2p.hook';
import { getCommonTrackingD2PAttributes } from 'helpers/oneClickSignup.helpers';

const SignupError = ({ ...props }) => {
  const eventHandler = useEventHandler();
  const { config } = useConfigLoader(true);

  const d2pConfig = useD2PConfig();

  const gotoCustomerSupport = e => {
    eventHandler(CLICK, { 'Click Type': 'Contact Support' });
    window.location.replace(config?.supportLinks?.submitNewRequestSupportLink);
  };

  const gotoCCMarketplace = e => {
    eventHandler(CLICK, { 'Click Type': 'Explore Offers' });
    window.location.replace(
      'https://www.creditsesame.com/credit-cards/for-good-credit/',
    );
  };

  const getErrorType = () => {
    const errorType = getGetParam('type');
    if (!errorType) return props.history.replace('/signupError?type=GENERAL');
    return errorType;
  };

  const getErrorTypePageName = errorType => {
    return errorPageNameMap[errorType] ? errorPageNameMap[errorType] : '';
  };

  const errorType = getErrorType();

  useEffect(() => {
    const { dispatch, app } = props;
    if (app.loggedIn) {
      dispatch(logout());
    }

    visitorReportPageView(
      `Signup Error Page - ${getErrorTypePageName(errorType)}`,
    );
    if (d2pConfig?.direct2Premium) {
      eventHandler(VIEW_PAGE, {
        'Page Name': 'Signup - Credit File Not Found',
        ...getCommonTrackingD2PAttributes({ d2pConfig }),
        'Error Type': errorType,
      });
    }
    //eslint-disable-next-line
  }, [errorType, d2pConfig?.direct2Premium]);

  // Reload on account lockout page -> redirect to signup.
  useEffect(() => {
    const { app } = props;
    if (!app.loggedIn && errorType === ACCOUNT_LOCKOUT) {
      props.history.replace('/signup');
    }
    //eslint-disable-next-line
  }, []);

  if (!errorType) {
    return <PageLoadingMask />;
  }

  return (
    <SignupErrorPrimaryView
      errorType={errorType}
      {...props}
      gotoCustomerSupport={gotoCustomerSupport}
      gotoCCMarketplace={gotoCCMarketplace}
      showButtonToExploreOffers={config?.signUpError?.showButtonToExploreOffers}
    />
  );
};

function mapStateToProps(state) {
  const { signup, app } = state;
  return {
    signup,
    app,
  };
}

const connectedSignupError = withRouter(connect(mapStateToProps)(SignupError));
export { connectedSignupError as SignupError };
