import classnames from 'classnames';
import { Menu } from 'components/navigation/Menu';
import Navbar from 'partial/Header/components/Navbar';
import SkipToContent from 'partial/Header/components/SkipToContent';
import React from 'react';

function getPseudoMenuElementClass(isMenuCollapsed, maskOn) {
  return isMenuCollapsed
    ? `fake-header-menu-container-thin`
    : !maskOn
    ? `fake-header-menu-container-thick`
    : `fake-header-menu-container-thin`;
}

const getDesktopMenuClasses = (isMenuCollapsed, maskOn) => {
  let desktopMenuClasses = `header-menu-container`;

  let classString = desktopMenuClasses;
  let hasGrayLayer = desktopMenuClasses.indexOf('grayLayer') > 0;
  let hasMenuCollapse = desktopMenuClasses.indexOf('menu-collapse') > 0;
  let hasMenuOpen = desktopMenuClasses.indexOf('menu-open') > 0;
  if (isMenuCollapsed) {
    classString = classString.replace('menu-open', '');
    if (!hasMenuCollapse) {
      classString = `${classString} menu-collapse`;
    }
    if (hasGrayLayer) {
      classString = classString.replace(' grayLayer', '');
    }
  } else {
    classString = classString.replace(' menu-collapse', '');
    if (!hasMenuOpen) {
      classString = `${classString} menu-open`;
    }
    if (!hasGrayLayer && maskOn) {
      classString = `${classString} grayLayer`;
    }
  }
  return classString;
};

const HeaderView = ({
  children,
  closeDesktopMenu,
  closeMobileMenu,
  collapseMenu,
  creditInfo,
  disclosureItemClicked,
  eventHandler,
  extraProps,
  forceHideMenuBar,
  forceMobileHideMenuBar,
  history,
  isActionPlanPage,
  isActionsModuleSelectedPage,
  isBoosterWaitlistPage,
  isHideHeader,
  isMenuCollapsed,
  isMobileMenuOpen,
  isOcfPage,
  isOcfRole,
  isPremiumStarterPage,
  isPremiumUser,
  isPublic,
  isRecurlyCreditReportYUHybridUser,
  isRecurlyHybridUser,
  isSignup,
  isUnsubscribe,
  loggedIn,
  logoLink,
  maskOn,
  menuItemClicked,
  menuType,
  headerMenuOpts,
  navbarShowing,
  notifications,
  numberOfActions,
  numberOfGoals,
  onMenuToggle,
  pathname,
  premiumDataConfig,
  removeHeader,
  secureLoggedIn,
  showLogo,
  showMenuBar,
  showPendingActions,
  showGoalsCounter,
  totalUnreadCount,
  userInfo,
  isPreviouslyPremiumPlusFlow,
  onClickReactivatePlus,
  plusLaunch,
  idProtectionOption,
  creditLockEnabled,
  creditManagerVisited,
  menuTermsPrivacyUrls,
}) => {
  const mainBodyClass = classnames('main-body-container', {
    isSignup,
    isUnsubscribe,
    isPublic,
    isPremiumStarterPage,
    isActionsModuleSelectedPage,
    isRecurlyCreditReportYUHybridUser,
  });

  const mainContainerClass = classnames('main-main-container', {
    isActionPlanPage,
  });

  return (
    <div data-testid="header" className={mainContainerClass}>
      {!isHideHeader && (
        <Navbar
          navbarShowing={navbarShowing}
          numberOfGoals={numberOfGoals}
          collapseMenu={collapseMenu}
          notifications={notifications}
          onMenuToggle={onMenuToggle}
          eventHandler={eventHandler}
          showLogo={showLogo}
          isSignup={isSignup}
          loggedIn={loggedIn}
          logoLink={logoLink}
          isPremiumUser={isPremiumUser}
          userInfo={userInfo}
          currentPath={pathname}
          showMenuBar={showMenuBar}
          isBoosterWaitlistPage={isBoosterWaitlistPage}
          isOcfRole={isOcfRole}
          menuType={menuType}
          headerMenuOpts={headerMenuOpts}
          forceHideMenuBar={forceHideMenuBar}
          forceMobileHideMenuBar={forceMobileHideMenuBar}
          isRecurlyHybridUser={isRecurlyHybridUser}
          extraProps={extraProps}
          isOcfPage={isOcfPage}
          isHideHeader={isHideHeader}
          removeHeader={removeHeader}
          numberOfActions={numberOfActions}
          showPendingActions={showPendingActions}
          showGoalsCounter={showGoalsCounter}
          premiumDataConfig={premiumDataConfig}
          secureLoggedIn={secureLoggedIn}
          totalUnreadCount={totalUnreadCount}
          creditManagerVisited={creditManagerVisited}
          history={history}
        />
      )}

      <div
        className={mainBodyClass}
        style={removeHeader ? { paddingTop: '0px' } : {}}
      >
        {/* collapsable menu desktop and mobile */}
        {loggedIn && showMenuBar && (
          <div className="nav-content">
            <SkipToContent />
            <div className={getDesktopMenuClasses(isMenuCollapsed, maskOn)}>
              <Menu
                collapsed={isMenuCollapsed}
                isMenuOpen={true}
                numberOfGoals={numberOfGoals}
                onClick={menuItemClicked}
                currentPath={pathname}
                disclosureItemClicked={disclosureItemClicked}
                datatestid="headerLoggedIn-mobile"
                closeDesktopMenu={closeDesktopMenu}
                maskOn={maskOn}
                unreadAlertCount={totalUnreadCount}
                isOcfRole={isOcfRole}
                creditInfo={creditInfo}
                numberOfActions={numberOfActions}
                showPendingActions={showPendingActions}
                showGoalsCounter={showGoalsCounter}
                eventHandler={eventHandler}
                history={history}
                premiumDataConfig={premiumDataConfig}
                userInfo={userInfo}
                isPremiumUser={isPremiumUser}
                isPreviouslyPremiumPlusFlow={isPreviouslyPremiumPlusFlow}
                onClickReactivatePlus={onClickReactivatePlus}
                plusLaunch={plusLaunch}
                idProtectionOption={idProtectionOption}
                creditLockEnabled={creditLockEnabled}
                creditManagerVisited={creditManagerVisited}
                menuTermsPrivacyUrls={menuTermsPrivacyUrls}
              />
            </div>
            <div className="header-menu-container-mobile">
              <div className={`${!isMobileMenuOpen ? '' : 'grayLayer'}`} />
              <Menu
                collapsed={false}
                numberOfGoals={numberOfGoals}
                isMenuOpen={isMobileMenuOpen}
                isMobileMenuOpen={isMobileMenuOpen}
                onClick={menuItemClicked}
                currentPath={pathname}
                disclosureItemClicked={disclosureItemClicked}
                datatestid="headerLoggedIn-desktop"
                closeMobileMenu={closeMobileMenu}
                unreadAlertCount={totalUnreadCount}
                isOcfRole={isOcfRole}
                creditInfo={creditInfo}
                numberOfActions={numberOfActions}
                showPendingActions={showPendingActions}
                showGoalsCounter={showGoalsCounter}
                eventHandler={eventHandler}
                history={history}
                premiumDataConfig={premiumDataConfig}
                userInfo={userInfo}
                isPremiumUser={isPremiumUser}
                isPreviouslyPremiumPlusFlow={isPreviouslyPremiumPlusFlow}
                onClickReactivatePlus={onClickReactivatePlus}
                plusLaunch={plusLaunch}
                idProtectionOption={idProtectionOption}
                creditLockEnabled={creditLockEnabled}
                creditManagerVisited={creditManagerVisited}
                menuTermsPrivacyUrls={menuTermsPrivacyUrls}
              />
            </div>
            {
              <div
                className={getPseudoMenuElementClass(isMenuCollapsed, maskOn)}
              ></div>
            }
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default HeaderView;
