import {
  RESET_ACCOUNT_VERIFICATION,
  ACCOUNT_VERIFICATION_OTP_ERROR,
  SET_ACCOUNT_VERIFICATION_DATA,
  SET_ACCOUNT_VERIFICATION_STEP,
  ACCOUNT_VERIFICATION_DUPLICATED_PHONE,
  ACCOUNT_VERIFICATION_DUPLICATED_EMAIL,
} from 'types/accountVerification.constants';

export const setAccountVerificationData = (step, data) => ({
  type: SET_ACCOUNT_VERIFICATION_DATA,
  step,
  ...data,
});

export const accountVerificationOtpError = () => ({
  type: ACCOUNT_VERIFICATION_OTP_ERROR,
});

export const resetAccountVerification = () => ({
  type: RESET_ACCOUNT_VERIFICATION,
});

export const setAccountVerificationStep = step => ({
  type: SET_ACCOUNT_VERIFICATION_STEP,
  step,
});

export const accountVerificationDuplicatedEmail = updatedEmail => ({
  type: ACCOUNT_VERIFICATION_DUPLICATED_EMAIL,
  updatedEmail,
});

export const accountVerificationDuplicatedPhone =
  updatedContactPhoneNumber => ({
    type: ACCOUNT_VERIFICATION_DUPLICATED_PHONE,
    updatedContactPhoneNumber,
  });
