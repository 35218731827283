import { LOGOUT } from 'types/app.constants';
import {
  RESET_VERIFY_ACCOUNT,
  SET_VERIFY_ACCOUNT_DATA,
  SET_VERIFY_ACCOUNT_EMAIL_CONFIRM,
  SET_VERIFY_ACCOUNT_STEP,
  SET_VERIFY_ACCOUNT_PHONE_NUMBER_CONFIRM,
  SET_EMAIL_OTP_REQUIREMENT,
  SET_PHONE_OTP_REQUIREMENT,
} from 'types/verifyAccount.constant';

const initialState = {
  step: 'WELCOME_VERIFICATION',
  prevStep: null,
  email: null,
  emailConfirmed: false,
  contactPhoneNumber: null,
  contactPhoneNumberConfirmed: false,
  duplicatedEmail: false,
  duplicatedContactPhoneNumber: false,
  isEmailVerifyOtp: false,
  isPhoneVerifyOtp: false,
};

export default function verifyAccountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VERIFY_ACCOUNT_DATA: {
      return {
        ...state,
        email: action.userInfo.email,
        emailConfirmed: action.userInfo.emailConfirmed,
        contactPhoneNumber: action.userInfo.contactPhoneNumber,
        contactPhoneNumberConfirmed:
          action.userInfo.contactPhoneNumberConfirmed,
        emaduplicatedEmaill: action.userInfo.duplicatedEmail,
        duplicatedContactPhoneNumber:
          action.userInfo.duplicatedContactPhoneNumber,
      };
    }
    case SET_VERIFY_ACCOUNT_EMAIL_CONFIRM: {
      return {
        ...state,
        emailConfirmed: true,
        isEmailVerifyOtp: false,
        prevStep: null,
      };
    }
    case SET_VERIFY_ACCOUNT_PHONE_NUMBER_CONFIRM: {
      return {
        ...state,
        contactPhoneNumberConfirmed: true,
        isPhoneVerifyOtp: false,
        prevStep: null,
      };
    }
    case SET_EMAIL_OTP_REQUIREMENT: {
      return {
        ...state,
        isEmailVerifyOtp: action.isEmailVerifyOtp,
        prevStep: action.prevStep,
        email: action.email,
      };
    }
    case SET_PHONE_OTP_REQUIREMENT: {
      return {
        ...state,
        isPhoneVerifyOtp: action.isPhoneVerifyOtp,
        prevStep: action.prevStep,
        contactPhoneNumber: action.contactPhoneNumber,
      };
    }
    case SET_VERIFY_ACCOUNT_STEP: {
      return { ...state, step: action.step };
    }
    case LOGOUT:
    case RESET_VERIFY_ACCOUNT:
      return initialState;
    default:
      return state;
  }
}
