import { getCookieValue } from 'helpers/cookie.helper';
import { mktTrackingCookieName } from 'pages/SignupPage/types/marketingAttribution.constants';
import {
  campaignIdAssociatedCopies,
  E2E_MARKETING_PARAM_COOKIE,
} from 'types/e2e.constants';

const copiesForCampaign = {
  [campaignIdAssociatedCopies.credit_cards]: {
    title: 'Sign up and see the right credit cards for your credit score',
    bullets: [
      'See card matches based on your score',
      'Compare rates, offers, and approval odds',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
  [campaignIdAssociatedCopies.credit_score]: {
    title: 'Sign up and get your FREE credit score',
    bullets: [
      'Get your score daily and see what’s impacting it',
      'Checking your score does not hurt your credit',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
  [campaignIdAssociatedCopies.loan]: {
    title: 'Sign up and see loans and rates based on your credit score',
    bullets: [
      'See loan offers based on your credit score',
      'Compare rates, offers, and approval odds',
      '100% free—does not impact your score',
    ],
  },
  [campaignIdAssociatedCopies.premium]: {
    title: 'Sign up and get your <br /> 3-Bureau credit reports and scores',
  },
  [campaignIdAssociatedCopies.credit_builder]: {
    title: 'Sign up to build your credit with your daily debit purchases',
    bullets: [
      'Build credit without a traditional credit card',
      'No security deposit, interest charges, or debt',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
  [campaignIdAssociatedCopies.credit_monitoring]: {
    title: 'Sign up to see your score and monitor your credit for FREE',
    bullets: [
      'See your credit score instantly—refresh it daily',
      'Get alerts about changes to your credit profile',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
  [campaignIdAssociatedCopies.credit_monitoringv2]: {
    title: 'Sign up to get FREE credit monitoring alerts and more',
    bullets: [
      'See your credit score instantly—refresh it daily',
      'Get alerts about changes to your credit profile',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
  [campaignIdAssociatedCopies.home_auto_loan]: {
    title: 'Sign up and see the right loans for your credit score',
    bullets: [
      'See offers for loans based on your score',
      'Compare rates  and approval odds',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
  [campaignIdAssociatedCopies.credit_report]: {
    title: 'Sign up and see your credit report summary for FREE',
    bullets: [
      'Check your current TransUnion credit score',
      'See what’s impacting your credit profile',
      'Get our Sesame Credit Builder MasterCard',
    ],
  },
};

export const useE2EAcquisition = () => {
  const inboundParamsCookie = getCookieValue(mktTrackingCookieName);
  const inboundParamsCookieObj =
    inboundParamsCookie && JSON.parse(decodeURIComponent(inboundParamsCookie));
  const campaignName = inboundParamsCookieObj?.[E2E_MARKETING_PARAM_COOKIE];

  let campaignCopy = {};
  const isPageAssociatedWithCampaign = !!campaignName;

  if (isPageAssociatedWithCampaign) {
    campaignCopy = copiesForCampaign[campaignName];
  }

  return {
    campaignName,
    campaignCopy,
    isPageAssociatedWithCampaign,
  };
};
