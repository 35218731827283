import { buildPartnerDecrypt } from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';

export async function partnerDecrypt(body, partner) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  const params = convertObjectToQueryString({ partner });
  return standardizedFetchHandler(
    buildPartnerDecrypt() + params,
    requestOptions,
  );
} 
