import { standardEventHandler } from 'actions/eventHandler.actions';
import { setPageMeta } from 'actions/tracking.actions';
import {
  brazeEventTrack,
  startBrazeSession,
} from 'externals/_tracking/braze/Braze.track';
import { tagInspectletSession } from 'externals/_tracking/inspectlet';
import {
  aliasVisitorUser,
  identifyUnifiedUser,
  identifyVisitorUser,
  visitorReportEvent,
  visitorReportPageView,
  visitorSetPageName,
} from 'externals/_tracking/mixpanel/mixpanel.track';
import { reportOptimizely } from 'externals/_tracking/optimizely/optimizely.track';
import { initOptSessionAttributes } from 'externals/_tracking/optimizely/optimizely.util';
import {
  CLICK,
  VIEW_PAGE,
} from 'externals/_tracking/types/eventTypes.constants';
import {
  ACCT_CREATION,
  ADDRESS,
  BOOSTER_CONGRATS,
  BOOSTER_ENROLL,
  CHECK_OPTIONS,
  CONFIRM_UPDATED_INFO,
  DM_ACCT_CREATION,
  DUPE_QUESTIONS,
  DUP_KBA_PHONE_VERIFICATION,
  GO_TO_NEXT_PAGE,
  GO_TO_PREVIOUS_PAGE,
  ONE_CLICK_SIGNUP_KBA_ENTER_HOME_ADDRESS,
  ONE_CLICK_SIGNUP_KBA_ENTER_NAME_AND_DOB,
  ONE_CLICK_SIGNUP_KBA_REVIEW_INFORMATION,
  PASSWORD,
  PHONE_NUMBER,
  PROFILE,
  QUESTIONS,
  REVALIDATE_EMAIL,
  REVIEWED_USER_INFO,
  REVIEW_INFO,
  SIGNUP_CASH,
  SSN,
  SSN_9_DIGIT,
  SUMMARY,
  TWO_FA_OTP_REQUEST,
  TWO_FA_OTP_VERIFY,
  UPDATE_INFO,
  UPDATE_USER_INFO,
} from 'pages/SignupPage/types/signup.constants';
import * as pageNames from 'types/mrph.pageName.constants';
import {
  SIGNUP_ACCT_CREATION,
  SIGNUP_ADDRESS,
  SIGNUP_BOOSTER_CONGRATS,
  SIGNUP_BOOSTER_ENROLL,
  SIGNUP_CASH_ENROLLMENT,
  SIGNUP_CHECK_OPTIONS,
  SIGNUP_DM_ACCT_CREATION,
  SIGNUP_DUPE_QUESTIONS,
  SIGNUP_DUPE_SSN,
  SIGNUP_DUP_KBA_PHONE_VERIFICATION,
  SIGNUP_KBA_ENTER_HOME_ADDRESS,
  SIGNUP_KBA_ENTER_NAME_AND_DOB,
  SIGNUP_KBA_REVIEW_INFORMATION,
  SIGNUP_PASSWORD,
  SIGNUP_PHONE_NUMBER,
  SIGNUP_PROFILE,
  SIGNUP_QUESTIONS,
  SIGNUP_REVALIDATE_EMAIL,
  SIGNUP_REVIEW_INFO,
  SIGNUP_SSN,
  SIGNUP_SSN_9_DIGIT,
  SIGNUP_SUMMARY,
  SIGNUP_TWO_FA_OTP_REQUEST,
  SIGNUP_TWO_FA_OTP_VERIFY,
  SIGNUP_UPDATE_INFO,
} from 'types/mrph.pages.constants';
import { optEventsAffiliateSignup } from './AffiliateSignup/AffiliateSignup.constants';
import { mxpMapDMCampaignSignup } from './DMCampaign/DMCampaignSignup.constants';
import { optEventsOneClickSignup } from './OneClickSignup/OneClickSignup.constants';
import {
  mxpMapPrefilledSignup,
  optEventsPrefilledSignup,
} from './PrefilledSignup/PrefilledSignup.constants';
/* 
WIP Migrating events to centralized config 
    - Signup steps page views and clicks supported
*/

export const OPT_SIGNUP_COMPLETE = 'signup_complete';
export const OPT_SIGNUP_ACCOUNT_CREATED = 'signup_account_created';

export const optEvents = {
  [ACCT_CREATION]: 'viewed_page_signup_1',
  [PROFILE]: 'signup_viewed_first_last_name',
  [ADDRESS]: 'signup_viewed_address',
  [PHONE_NUMBER]: 'signup_viewed_phone_num',
  [SSN]: 'signup_viewed_ssn',
  [SSN_9_DIGIT]: 'signup_viewed_ssn_9_digit',
  [PASSWORD]: 'signup_viewed_password',
  [QUESTIONS]: 'ViewPage2B',
  [DUPE_QUESTIONS]: 'signup_viewed_dupe_ssn_2b',
  DUPE_SSN: 'welcome_screen_ssn_in_use',
  [BOOSTER_ENROLL]: 'signup_viewed_booster_waitlist',
  [BOOSTER_CONGRATS]: 'signup_viewed_booster_congrats',
  [SIGNUP_CASH]: 'signup_cash',
  [DM_ACCT_CREATION]: 'viewed_page_dm_signup_1',
  [DUP_KBA_PHONE_VERIFICATION]: 'signup_viewed_dup_kba_phone_verification',
  [REVALIDATE_EMAIL]: 'signup_viewed_revalidate_email_TU',
};

// maps signup pages to pageName in mrph.pages.constants
export const mxpMap = {
  [ACCT_CREATION]: SIGNUP_ACCT_CREATION,
  [PROFILE]: SIGNUP_PROFILE,
  [ADDRESS]: SIGNUP_ADDRESS,
  [PHONE_NUMBER]: SIGNUP_PHONE_NUMBER,
  [SSN]: SIGNUP_SSN,
  [SSN_9_DIGIT]: SIGNUP_SSN_9_DIGIT,
  [PASSWORD]: SIGNUP_PASSWORD,
  [QUESTIONS]: SIGNUP_QUESTIONS,
  [DUPE_QUESTIONS]: SIGNUP_DUPE_QUESTIONS,
  DUPE_SSN: SIGNUP_DUPE_SSN,
  [SUMMARY]: SIGNUP_SUMMARY,
  [BOOSTER_ENROLL]: SIGNUP_BOOSTER_ENROLL,
  [BOOSTER_CONGRATS]: SIGNUP_BOOSTER_CONGRATS,
  [SIGNUP_CASH]: SIGNUP_CASH_ENROLLMENT,
  [DM_ACCT_CREATION]: SIGNUP_DM_ACCT_CREATION,
  [DUP_KBA_PHONE_VERIFICATION]: SIGNUP_DUP_KBA_PHONE_VERIFICATION,
  [REVALIDATE_EMAIL]: SIGNUP_REVALIDATE_EMAIL,
  [TWO_FA_OTP_REQUEST]: SIGNUP_TWO_FA_OTP_REQUEST,
  [TWO_FA_OTP_VERIFY]: SIGNUP_TWO_FA_OTP_VERIFY,

  [REVIEW_INFO]: SIGNUP_REVIEW_INFO,
  [UPDATE_INFO]: SIGNUP_UPDATE_INFO,
  [CHECK_OPTIONS]: SIGNUP_CHECK_OPTIONS,
  [ONE_CLICK_SIGNUP_KBA_ENTER_NAME_AND_DOB]: SIGNUP_KBA_ENTER_NAME_AND_DOB,
  [ONE_CLICK_SIGNUP_KBA_ENTER_HOME_ADDRESS]: SIGNUP_KBA_ENTER_HOME_ADDRESS,
  [ONE_CLICK_SIGNUP_KBA_REVIEW_INFORMATION]: SIGNUP_KBA_REVIEW_INFORMATION,
};

export const mxpMapCickTypes = {
  [REVIEWED_USER_INFO]: 'Reviewed user information is correct',
  [UPDATE_USER_INFO]: 'Update user information',
  [CONFIRM_UPDATED_INFO]: 'Confirm the updated information',
  [GO_TO_NEXT_PAGE]: 'Go to Next Page',
  [GO_TO_PREVIOUS_PAGE]: 'Go to Previous Page',
};

export function initUserTracking(
  registrationResponse,
  userId,
  sessionIdentifier,
) {
  tagInspectletSession({ usrTkn: userId });

  //Braze tracking
  startBrazeSession(userId);

  //if userId then first time user, alias user to visitor bucket
  if (registrationResponse.userId && registrationResponse.userId !== null) {
    aliasVisitorUser(userId);
  } else if (
    registrationResponse.code &&
    registrationResponse.code === 'SU_1002'
  ) {
    identifyVisitorUser(userId);
    visitorReportEvent('Email in use');
  }
  identifyUnifiedUser(userId);
  initOptSessionAttributes(userId, sessionIdentifier);
}

function getOptEvents(extraProps) {
  if (extraProps?.isOneClickSignup) {
    return optEventsOneClickSignup;
  } else if (extraProps?.isPrefilledSignup) {
    return optEventsPrefilledSignup;
  } else if (extraProps?.isAffiliateSignup) {
    return optEventsAffiliateSignup;
  } else {
    return optEvents;
  }
}

function getMixpanelMap(extraProps) {
  if (extraProps?.isPrefilledSignup) {
    return mxpMapPrefilledSignup;
  } else if (extraProps?.isDmCampaign) {
    return mxpMapDMCampaignSignup;
  } else {
    return mxpMap;
  }
}

export function reportPageViews(step, eventAttributes, extraProps) {
  const finalOptEvents = getOptEvents(extraProps);
  const finalMxpMap = getMixpanelMap(extraProps);
  visitorReportPageView(finalMxpMap[step], eventAttributes, extraProps);
  reportOptimizely(finalOptEvents[step]);
  brazeEventTrack('View Page ' + finalMxpMap[step], {});
  console.log(
    `Reporting page views for opt: ${finalOptEvents[step]} and mxpVisitor/braze: ${finalMxpMap[step]} `,
  );
}

export const reportAccountCreated = (eventAttributes, extraProps) => {};

export const reportSignupPageView =
  (step, eventAttributes, extraProps) => dispatch => {
    const finalStep = mxpMap[step] ? mxpMap[step] : step;
    const pageName = pageNames[finalStep];
    const finalOptEvents = getOptEvents(extraProps);
    dispatch(setPageMeta(finalStep));
    dispatch(
      standardEventHandler(
        VIEW_PAGE,
        Object.assign({ 'Page Name': pageName }, eventAttributes),
      ),
    );
    reportOptimizely(finalOptEvents[step]);
    brazeEventTrack('View Page ' + pageName, {});
  };

export function reportSetPageName(step, extraProps) {
  const finalMxpMap = getMixpanelMap(extraProps);
  visitorSetPageName(finalMxpMap[step]);
}

export const signupSetPageName = step => dispatch => {
  const finalStep = mxpMap[step] ? mxpMap[step] : step;
  const pageName = pageNames[finalStep];
  dispatch(setPageMeta(finalStep));
  visitorSetPageName(pageName);
};

export const reportClicks = step => dispatch => {
  const pageName = pageNames[mxpMap[step]];
  dispatch(
    standardEventHandler(CLICK, {
      'Click Type': `${pageName}`,
      'Click Title': `Submit step - ${pageName}`,
    }),
  );
  console.log(`Reporting click ${pageName} title: Submit step - ${pageName}`);
};

export const reportPageClicks = (page, click) => dispatch => {
  const pageName = pageNames[mxpMap[page]];
  const clickType = mxpMapCickTypes[click];
  dispatch(
    standardEventHandler(CLICK, {
      'Page Name': `${pageName}`,
      'Click Type': `${clickType}`,
    }),
  );
};

export const signupReportApiError = (logger, error, ref) => {
  logger.reportAPIError(error, ref);
  visitorReportEvent('API Error', Object.assign({ Ref: ref }, error));
};
