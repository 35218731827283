import { formatPhoneNumber } from 'helpers/signup.helpers';

import * as csidAlertTypes from 'pages/Alerts/types/csid.constants';
import moment from 'moment';
import { convertTUDatetoFormattedDate } from 'helpers/dateHelper';
import { formatAddress } from 'helpers/address.helper';
import { commafy } from 'helpers/number';
import { formatCurrency, maskCardNumber } from 'helpers/money.helper';
import { formatPhoneWithDashes } from 'helpers/phone.helper';
import { CALL_HELP } from 'pages_alerts/types/csid.alert.config.constants';
import { DARK_WEB_MONITORING } from 'pages/Alerts/types/csid.constants';

export const getPremiumCategoryText = category => {
  switch (category) {
    case csidAlertTypes.CHANGE_OF_ADDRESS_ALERT:
    case csidAlertTypes.CHANGE_OF_ADDRESS_REPORT:
      return 'Change of Address Alert';
    case csidAlertTypes.COURT_RECORDS_ALERT:
    case 'COURT_RECORDS_REPORT':
      return 'Court Records Data';
    case csidAlertTypes.CYBER_AGENT_ALERT:
    case 'CYBER_AGENT_REPORT':
      return 'Dark Web Report Details';
    case csidAlertTypes.IDENTITY_TRACE_ALERT:
    case 'IDENTITY_TRACE_REPORT':
      return 'Identity Trace';
    case csidAlertTypes.NON_CREDIT_ALERT:
      return 'Non-Credit Loan Data';

    case 'NON_CREDIT_REPORT':
      return 'Non-Credit Loan Data';
    case csidAlertTypes.SEX_OFFENDER_ALERT:
    case 'SEX_OFFENDER_REPORT':
      return 'Sex Offender';
    case csidAlertTypes.SSN_TRACE_ALERT:
    case 'SSN_TRACE_REPORT':
      return 'SSN Trace Data';
    case csidAlertTypes.DARK_WEB_MONITORING:
      return 'Dark Web Alert';
    case csidAlertTypes.NAME_CHANGE:
      return 'Name Change Alert';
    case csidAlertTypes.ADDRESS_CHANGE:
      return 'Address Change Alert';
    case csidAlertTypes.CRIMINAL_ACTIVITY:
      return 'Court Record Alert';
    default:
      return '';
  }
};

const blackMarketRecordMap = {
  'Date of Birth': 'dob',
  'Address State': 'addrstate',
  'Bank Route': 'bankroute',
  'Ebay Password': 'ebaypwd',
  Email: 'email',
  Country: 'addrcountry',
  'Credit Card Number': 'cardnum',
  'Credit Card Expiry': 'cardexpdate',
  SSN: 'ssn',
  Phone: 'phone',
  'Passport Number': 'passportnum',
  'Passport Issue Date': 'passportissuedate',
  'Passport Expiry Date': 'passportexpdate',
  'Breached Site': 'breachedsite',
  Severity: 'severity',
};
const getBlackMarketDetails = (title, item) => {
  let value = item[blackMarketRecordMap[title]];

  if (title === 'Expiration MM/YY') {
    value = moment(value, 'MM/YY');
  }
  if (title === 'Credit Card Number') {
    value = maskCardNumber(value);
  }

  if (value) {
    return {
      val: value,
      source: blackMarketRecordMap[title].match,
    };
  }
};

const courtRecordMap = {
  'Case Type': 'caseType',
  'Source Name': 'sourceName',
  'Source State': 'sourceState',
  'Offense Date': 'OffenseDate',
  'Conviction Date': 'ConvictionDate',
};
const getCourtRecordDetails = (title, item) => {
  let value = item[courtRecordMap[title]];
  if (value) {
    if (title === 'Source Name') {
      value = value.toLowerCase();
    }
    return {
      val: value ? value : null,
      source: courtRecordMap[title].match,
    };
  }
};

const changeOfAddressRecordMap = {
  'First Name': 'first',
  'Last Name': 'last',
  Address: 'address',
  City: 'city',
  State: 'st',
  Zip: 'zip',
  'Move Date': 'moveda',
};

const getChangeOfAddressRecordDetails = (title, item) => {
  let value = item[changeOfAddressRecordMap[title]];

  if (title === 'Address') {
    value = value.toLowerCase();
  }

  if (value) {
    return {
      val: value,
      source: changeOfAddressRecordMap[title].match,
    };
  }
};

const inquiresRecordMap = {
  Date: 'date',
  Merchant: 'merchant',
  Location: 'city-state-zip',
  Time: 'time',
  Reason: 'reason',
};

const loansRecordMap = {
  Date: 'date',
  Amount: 'amount',
  Status: 'status',
  Merchant: 'merchant',
  Location: 'merchantLocation',
  Phone: 'phone',
};

const getNonCreditRecordDetails = (title, item, type) => {
  if (type === 'Inquiries') {
    let value = item[inquiresRecordMap[title]];
    if (title === 'Reason') {
      value = value ? value : 'N/A';
    }
    if (value) {
      return {
        val: value,
        source: inquiresRecordMap[title].match,
      };
    }
  }
  if (type === 'Loans') {
    let value = item[loansRecordMap[title]];
    if (value) {
      if (title === 'Amount') {
        value = formatCurrency(value);
      }

      if (title === 'Phone') {
        value = formatPhoneWithDashes(value);
      }
      return {
        val: value,
        source: loansRecordMap[title].match,
      };
    }
  }
};

const ssnTraceRecordMap = {
  Rank: 'rank',
  Address: 'address',
  City: 'city',
  State: 'state',
  'Zip Code': 'zip',
  'First Name': 'first',
  'Last Name': 'last',
  'Date of Birth': 'dob',
  'Date Reported': 'date',
};
const getSsnTraceRecordDetails = (title, item) => {
  let value = item[ssnTraceRecordMap[title]];
  if (title === 'Address' || title === 'City') {
    value = value.toLowerCase();
  }
  return {
    val: value ? value : 'Not Provided',
    source: ssnTraceRecordMap[title].match,
  };
};

export const getWebAttribute = (title, alertDetails, category) => {
  const {
    alertType,
    /* Dark Web Monitoring */
    dateFound,
    description,
    email,
    recommendations,
    source,
    type,
    notifiedDate,
    /* Name Change */
    firstName,
    middleName,
    lastName,
    nameIndicator,
    prefix,
    suffix,
    /* Address Change */
    address1,
    address2,
    city,
    state,
    zipCode,
    /* Criminal Change */
    yearOfBirth,
    gender,
    recordType,
    code,
    released,
    reportingAgency,
    agencyPhoneNumber,
    bookingDate,
    agencyAddress1,
    agencyCity,
    agencyZipCode,
    agencyState,
    breachName,
    passwordFound,
  } = alertDetails || {};
  switch (title) {
    case 'Breach Name':
      return breachName;
    case 'Password Found?':
      return passwordFound;
    case 'Bureau':
      return 'TransUnion';
    case 'Alert Type':
    case 'Type':
      if (category === DARK_WEB_MONITORING) {
        return type;
      }
      return alertType?.length > 0 ? alertType : recordType;
    case 'Date Processed':
      return notifiedDate ?? '';
    case 'Date Found':
      return dateFound ?? '';
    case 'Description':
    case 'Charge Description':
      return description;
    case 'Email Address':
      return email;
    case 'Recommendations':
      return recommendations;
    case 'Source':
      return source;
    case 'Name':
      return `${firstName} ${middleName} ${lastName}`;
    case 'First Name':
      return firstName;
    case 'Middle Name':
      return middleName;
    case 'Last Name':
      return lastName;
    case 'Name Indicator':
      return nameIndicator;
    case 'Prefix':
      return prefix;
    case 'Suffix':
      return suffix;
    case 'Address Line 1':
      return address1;
    case 'Address Line 2':
      return address2;
    case 'City Name':
      return city;
    case 'State':
      return state;
    case 'Zip Code':
      return zipCode;
    case 'Alert Subtype':
      return 'Enhanced Alert';
    case 'Year of Birth':
      return yearOfBirth;
    case 'Gender':
      return gender;
    case 'Booking Date':
      return bookingDate ?? '';
    case 'Charge Code':
      return code;
    case 'Released':
      return released;
    case 'Reporting Agency':
      return reportingAgency;
    case 'Reporting Agency Address':
      return (
        <>
          <div>{`${agencyAddress1}`}</div>
          <div>{`${agencyCity}, ${agencyState} ${agencyZipCode}`}</div>
        </>
      );
    case 'Agency Phone Number':
      return formatPhoneNumber(agencyPhoneNumber);
    default:
      return '';
  }
};

export const getCsidAttribute = (title, alertDetails, category, item, type) => {
  if (
    category === csidAlertTypes.CYBER_AGENT_ALERT ||
    category === 'CYBER_AGENT_REPORT'
  ) {
    return getBlackMarketDetails(title, item);
  }
  if (
    category === csidAlertTypes.COURT_RECORDS_ALERT ||
    category === csidAlertTypes.COURT_RECORDS_REPORT
  ) {
    return getCourtRecordDetails(title, item);
  }
  if (
    category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
    category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
  ) {
    return getChangeOfAddressRecordDetails(title, item);
  }
  if (
    category === csidAlertTypes.NON_CREDIT_ALERT ||
    category === csidAlertTypes.NON_CREDIT_REPORT
  ) {
    return getNonCreditRecordDetails(title, item, type);
  }
  if (
    category === csidAlertTypes.SSN_TRACE_ALERT ||
    category === csidAlertTypes.SSN_TRACE_REPORT
  ) {
    return getSsnTraceRecordDetails(title, item);
  }
  switch (title) {
    case 'Phone':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return formatPhoneNumber(
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.phone,
        );
      }
      return null;
    case 'Address':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails?.report?.record?.[0]?.address?.toLowerCase();
      } else if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        const dataPoint =
          alertDetails?.transaction?.data[
            alertDetails?.transaction?.data?.length - 1
          ];
        const { address, city, state, zip } = dataPoint;
        return formatAddress({
          street: address.toLowerCase(),
          city: city.toLowerCase(),
          state: state,
          zip: zip,
        });
      } else if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.[
          'city-state-zip'
        ];
      }
      return null;
    case 'City':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails.report.record[0].city.toLowerCase();
      }
      return null;
    case 'Zip':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails.report.record[0].zip;
      }
      return null;
    case 'First Name':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails.report.record[0].first;
      } else if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        return alertDetails.transaction.data[0].first.toLowerCase();
      } else if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        let fn = alertDetails.report['first-name'];
        return fn ? fn.toLowerCase() : null;
      }
      return null;
    case 'Last Name':
      if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        return alertDetails.transaction.data[0].last.toLowerCase();
      } else if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        let ln = alertDetails.report['last-name'];
        return ln ? ln.toLowerCase() : null;
      }
      return null;
    case 'Source':
      if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        return alertDetails.transaction.data[0].source;
      }
      return null;
    case 'Transaction Date':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        let td = alertDetails.report['tran-date'];
        return td ? convertTUDatetoFormattedDate(td) : null;
      }
      return null;
    case 'Date':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return (
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.date || null
        );
      }
      return null;
    case 'Merchant':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return (
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.merchant ||
          null
        );
      }
      return null;
    case 'Loan Amount':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        const amount =
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.amount;
        return amount >= 0 ? '$' + commafy(amount) : null;
      }
      return null;
    case 'Status':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return (
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.status || null
        );
      }
      return null;
    default:
      return null;
  }
};

export const getConfigForCSIDAlert = alertType => {
  switch (alertType) {
    case csidAlertTypes.CHANGE_OF_ADDRESS_ALERT:
    case csidAlertTypes.CHANGE_OF_ADDRESS_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiRecordsList: true,
        detailTitles: [
          'First Name',
          'Last Name',
          'Address',
          'City',
          'State',
          'Zip',
          'Move Date',
        ],
        description:
          'This Change of Address alert lists any change of address requests made with the U.S. Postal Service that may be affiliated with your personal information. Please review your alert to ensure that there are no change of address requests that you do not recognize.',
        description2:
          'If the alert contains a change of address request that is not familiar, and you believe to be fraudulent or that you may be a victim of identity theft, please contact a Specialist at 877-751-1859. A Specialist will provide guidance on the next step in restoring your identity.',
        alertDetailsHeader: 'Change of Address Records',
        lineType: 'negative',
        image: true,
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.SSN_TRACE_ALERT:
    case csidAlertTypes.SSN_TRACE_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiRecordsList: true,
        detailTitles: [
          'Rank',
          'Address',
          'City',
          'State',
          'Zip Code',
          'First Name',
          'Last Name',
          'Date of Birth',
          'Date Reported',
        ],
        description:
          'This SSN Trace alert lists all of the names, addresses, and aliases found in public records associated with your Social Security number.',
        steps: [
          'Please review your SSN Trace alert to ensure that there are no names on the report that you do not recognize. If you see an address that does not match a location that you have lived at in the past, please review your other reports to see if this address also turns up in any other reports.',
          'If the unknown address does not turn up in other reports, there is a low chance of identity fraud. Sometimes an incorrect address can be an error and sometimes an incorrect address can signal an instance of identity fraud. If the report contains names that are not familiar to you, then there is an error in the public record information reported to CSID or you may be a victim of identity theft. If you cannot find another location in your alerts where the name or alias has turned up, there is a less likely chance of identity theft. For instance, if your credit report does not show the same name and alias that you see in this alert, there is a low chance that the appearance of the unknown name showing up in this alert is an indicator of fraud.',
          'If after reviewing your reports, you feel you are a victim of identity theft, please contact a Specialist at 877-751-1859.  A Specialist will provide guidance on the next step in restoring your identity.',
        ],
        alertDetailsHeader: 'Address Records',
        image: true,
        lineType: 'negative',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.NON_CREDIT_ALERT:
    case csidAlertTypes.NON_CREDIT_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiDetailAndRecordsList: true,
        alertDetailsHeader: 'Inquiry and Loan Records',
        steps: [
          'If you have a Non-Credit Loan Report, please review your report to be sure it contains accurate information. If you do not recognize account inquiries or loan applications, these could be errors by the financial institution or they could be potential identity theft.',
          'If there are items on your report that you do not recognize, please contact the coinciding financial institution immediately. If there are any open accounts or loans that you did not open, close those accounts immediately.',
          'If after working with your financial institution, you feel you are a victim of identity theft, please contact a Specialist at 877-751-1859.  A Specialist will provide guidance on the next step in restoring your identity.',
        ],
        detailTitles: [
          {
            header: 'Inquiries',
            titles: ['Date', 'Merchant', 'Location', 'Time', 'Reason'],
          },
          {
            header: 'Loans',
            titles: [
              'Date',
              'Amount',
              'Status',
              'Merchant',
              'Location',
              'Phone',
            ],
          },
        ],
        description:
          'CSID provides reports of any Non-Credit Loan activities associated with your name, date of birth, and Social Security Number. This type of activity can include payday loans where someone can get a small, short-term, high-rate loan from a check casher, without a credit inquiry. These loans can be made with fraudulent checks, identity documents and do not require credit inquiries. This report is a good source to monitor for suspicious activities that might be indicators of identity theft.',
        description2:
          'Note: You may not have a Non-Credit Loan Report if there have been no loan applications or inquiries on record.',
        image: true,
        lineType: 'negative',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.CYBER_AGENT_ALERT:
    case csidAlertTypes.CYBER_AGENT_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiRecordsList: true,
        detailTitles: [
          'Date of Birth',
          'Address State',
          'Bank Route',
          'Ebay Password',
          'Email',
          'Country',
          'Credit Card Number',
          'Credit Card Expiry',
          'SSN',
          'Phone',
          'Passport Number',
          'Passport Issue Date',
          'Passport Expiry Date',
          'Breached Site',
          'Severity',
        ],
        description:
          'Advanced Monitoring helps reduce your risk of identity theft by continuously monitoring criminal chat rooms, news groups and other websites where criminals trade stolen identities...',
        isStepsTitleInline: true,
        steps: [
          'Review your CyberAgent report.',
          'Cancel any compromised cards.',
          'Contact a Specialist if needed.',
        ],
        alertDetailsHeader: 'Record Information',
        image: true,
        lineType: 'negative',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.DARK_WEB_MONITORING:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: [
          'Alert Type',
          'Date Processed',
          'Breach Name',
          'Date Found',
          'Description',
          'Email Address',
          'Password Found?',
          'Recommendations',
          'Source',
          'Type',
        ],
        description:
          'We found the following information on black market websites.',
        image: true,
        lineType: 'neutral',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.NAME_CHANGE:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: [
          'Type',
          'Bureau',
          'First Name',
          'Middle Name',
          'Last Name',
          'Name Indicator',
          'Prefix',
          'Suffix',
        ],
        description: 'A new record has been found for you.',
        image: true,
        lineType: 'neutral',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.ADDRESS_CHANGE:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: [
          'Type',
          'Bureau',
          'Address Line 1',
          'Address Line 2',
          'City Name',
          'State',
          'Zip Code',
          'Alert Subtype',
        ],
        description: 'A new record has been found for you.',
        image: true,
        lineType: 'neutral',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.CRIMINAL_ACTIVITY:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: [
          'Name',
          'Year of Birth',
          'Gender',
          'Type',
          'Booking Date',
          'Charge Code',
          'Charge Description',
          'Released',
          'Reporting Agency',
          'Reporting Agency Address',
          'Agency Phone Number',
        ],
        description: 'A new record has been found in your background.',
        image: true,
        lineType: 'neutral',
        callHelp: CALL_HELP,
      };
    case csidAlertTypes.COURT_RECORDS_ALERT:
    case csidAlertTypes.COURT_RECORDS_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiRecordsList: true,
        steps: [
          'Please review your court records report to verify that any citations, arrests, or convictions are accurate.',
          'If you see a court record tied to your name and birth date that you do not recognize, please contact a Specialist at 877-751-1859.  A Specialist will provide guidance on the next step in restoring your identity.',
        ],
        alertDetailsHeader: 'Court Records',
        detailTitles: [
          'Case Type',
          'Source Name',
          'Source State',
          'Offense Date',
          'Conviction Date',
        ],
        description:
          'This alert includes information from county courts, state courts, the Department of Corrections, the Administration of the Courts, and other legal agencies that matches your name and date of birth. The types of offenses searched for include felonies, misdemeanors, sexual offenses, traffic citations, and more.',
        image: true,
        lineType: 'negative',
        callHelp: CALL_HELP,
      };
    default:
      return [];
  }
};
