import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './Link.scss';

/* Need to wrap all the links for track event handler */
function MrphLink({
  children,
  onClickCallback,
  to = '#',
  datatestid,
  className,
  isInline,
  linkClassName,
  isDisplayNone,
  enableWrapperTabIndex,
  ariaLabel = 'Link',
  title = '',
  target,
  rel,
  ...extraProps
}) {
  const linkRef = useRef(null);

  const handleKeyPress = e => {
    if (e.key === 'Enter' && linkRef.current) {
      linkRef.current.click();
    }
  };

  const internalOnClick = e => {
    if (onClickCallback) onClickCallback(extraProps);
    if (enableWrapperTabIndex) handleKeyPress(e);
  };

  const linkClasses = classnames(
    'MrphLink router-link secondary_text_light',
    linkClassName,
  );

  const displayClass = classnames({
    inline: isInline,
    'inline-block': !isInline && !isDisplayNone,
    none: isDisplayNone,
  });

  const style = {
    display: displayClass,
  };

  return (
    <div
      data-testid={datatestid}
      onClick={internalOnClick}
      onKeyPress={internalOnClick}
      className={className}
      style={style}
      role="link"
      aria-label={ariaLabel}
      tabIndex={enableWrapperTabIndex ? 0 : undefined}
    >
      <Link
        to={to}
        className={linkClasses}
        replace={extraProps.replace}
        tabIndex={enableWrapperTabIndex ? -1 : undefined}
        ref={linkRef}
        aria-label={ariaLabel}
        title={title}
        target={target}
        rel={rel}
      >
        {children}
      </Link>
    </div>
  );
}

MrphLink.defaultProps = {
  isInline: false,
};

export { MrphLink as Link };
