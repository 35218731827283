import {
  SET_FORGOT_PASSWORD_STEP,
  SUBMIT_EMAIL_ERROR,
  SUBMIT_PHONE_METHOD_SUCCESS,
  VERIFY_PHONE_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_SSN_ERROR,
  UPDATE_PASSWORD_ERROR,
  CLEAR_FORGOT_PASSWORD_STATE,
  INIT_FORGOT_PASSWORD_EMAIL_LINK,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  SET_CODE_VALUE,
  VERIFY_SSN,
  SET_TOKEN_FORGOT_PASSWORD,
} from '../../PasswordReset.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  step: 'CHOOSE_METHOD',

  chooseMethodFormError: null,
  verifyOtpMessage: null,
  codeValue: '',
  resendAttempts: 2,
  codeAttempts: 0,
  verifySsnMessage: null,
  fullSsn: false,
  updatePasswordMessage: null,
  token: null,
};

const forgotMyPasswordReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });
    case SUBMIT_EMAIL_ERROR:
      return Object.assign({}, state, {
        chooseMethodFormError: action.chooseMethodFormError,
      });
    case SUBMIT_PHONE_METHOD_SUCCESS:
      return Object.assign({}, state, {
        maskedPhoneNumber: action.maskedPhoneNumber,
      });
    case VERIFY_PHONE_ERROR:
      return Object.assign({}, state, {
        phoneErrorMessage: action.phoneErrorMessage,
      });
    case SET_CODE_VALUE:
      return Object.assign({}, state, {
        codeValue: action.codeValue,
      });
    case RESEND_CODE_SUCCESS:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
        codeValue: '',
        resendAttempts: action.resendAttempts,
        codeAttempts: 0,
      });
    case RESEND_CODE_ERROR:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
      });
    case VERIFY_OTP_SUCCESS:
    case SET_TOKEN_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        token: action.token,
      });
    case VERIFY_OTP_ERROR:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
        codeValue: '',
        codeAttempts: state.codeAttempts + 1,
      });
    case VERIFY_SSN_ERROR:
      return Object.assign({}, state, {
        verifySsnMessage: action.verifySsnMessage,
        fullSsn: true,
      });
    case UPDATE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        updatePasswordMessage: action.updatePasswordMessage,
      });
    case INIT_FORGOT_PASSWORD_EMAIL_LINK:
      return Object.assign({}, state, {
        step: VERIFY_SSN,
        token: action.token,
      });
    case CLEAR_FORGOT_PASSWORD_STATE:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default forgotMyPasswordReducer;
