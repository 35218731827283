//OTP Screens
export const ENTER_CODE = 'ENTER_CODE';
export const CHOOSE_DELIVERY_METHOD = 'CHOOSE_DELIVERY_METHOD';

//TU OTP item responses
export const ENTER_PASSCODE = 'Enter the passcode you received';
export const DELIVER_SMS = 'Deliver passcode via Text Message';
export const DELIVER_PHONE = 'Deliver passcode via Voice Call';

//TU OTP
export const TU_SMS = 'SMS';
export const TU_PHONE = 'Voice';

//Dup OTP
export const DUP_SMS = 'SMS_OTP';
export const DUP_EMAIL = 'EMAIL_OTP';
