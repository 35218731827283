import React from 'react';
import Icon from '@ant-design/icons';
import Badge from 'components/Badge/Badge';
import { ReactComponent as AlertsOutlineWhite } from 'style/_assets/icons/alerts-outline-white.svg';
import { ReactComponent as AlertsOutline } from 'style/_assets/icons/alerts-outline.svg';
import { ReactComponent as AlertsFilled } from 'style/_assets/icons/alerts-filled.svg';

const NotificationIconSection = props => {
  const { isPlusUser, totalUnreadCount } = props;

  if (totalUnreadCount === 0) {
    return isPlusUser ? (
      <Icon
        className="text-24"
        component={AlertsOutlineWhite}
        aria-label="No alerts for Plus users"
        role="img"
      />
    ) : (
      <Icon
        className="text-24"
        component={AlertsOutline}
        aria-label="No alerts"
        role="img"
      />
    );
  } else {
    return (
      <>
        <Badge className="pos-abs" style={{ margin: '-16px -18px 0 0' }} />
        <Icon
          className="text-24"
          component={AlertsFilled}
          aria-label={`You have ${totalUnreadCount} unread notifications`}
          role="img"
        />
      </>
    );
  }
};

export default NotificationIconSection;
