import MenuList from 'customMenuListPath/MenuList';
import React from 'react';
import './Menu.scss';

function Menu({
  closeDesktopMenu,
  closeMobileMenu,
  collapsed,
  creditInfo,
  currentPath,
  datatestid,
  disclosureItemClicked,
  eventHandler,
  history,
  isMenuOpen,
  isMobileMenuOpen,
  isOcfRole,
  maskOn,
  numberOfActions,
  numberOfGoals,
  onClick,
  premiumDataConfig,
  showPendingActions,
  showGoalsCounter,
  unreadAlertCount,
  userInfo,
  isPremiumUser,
  isPreviouslyPremiumPlusFlow,
  onClickReactivatePlus,
  plusLaunch,
  idProtectionOption,
  creditLockEnabled,
  creditManagerVisited,
  menuTermsPrivacyUrls,
}) {
  return (
    <>
      {isMenuOpen && (
        <div className="main-menu">
          <MenuList
            closeMobileMenu={closeMobileMenu}
            numberOfGoals={numberOfGoals}
            closeDesktopMenu={closeDesktopMenu}
            currentPath={currentPath}
            collapsed={collapsed}
            datatestid={datatestid ? `${datatestid}` : null}
            onClick={onClick}
            disclosureItemClicked={disclosureItemClicked}
            isMobileMenuOpen={isMobileMenuOpen}
            maskOn={maskOn}
            unreadAlertCount={unreadAlertCount}
            isOcfRole={isOcfRole}
            creditInfo={creditInfo}
            numberOfActions={numberOfActions}
            showPendingActions={showPendingActions}
            showGoalsCounter={showGoalsCounter}
            history={history}
            eventHandler={eventHandler}
            premiumDataConfig={premiumDataConfig}
            userInfo={userInfo}
            isPremiumUser={isPremiumUser}
            isPreviouslyPremiumPlusFlow={isPreviouslyPremiumPlusFlow}
            onClickReactivatePlus={onClickReactivatePlus}
            plusLaunch={plusLaunch}
            idProtectionOption={idProtectionOption}
            creditLockEnabled={creditLockEnabled}
            creditManagerVisited={creditManagerVisited}
            menuTermsPrivacyUrls={menuTermsPrivacyUrls}
          />
        </div>
      )}
    </>
  );
}

const memoizedMenu = Menu; //React.memo(Menu);

export { memoizedMenu as Menu };
