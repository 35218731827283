import {
  VERIFY_OTP_UPDATE_CODE,
  VERIFY_OTP_UPDATE_CODE_ATTEMPTS,
  VERIFY_OTP_UPDATE_SENT_ATTEMPTS,
  VERIFY_OTP_RESET,
} from 'types/verifyOtp.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  codeValue: '',
  sentAttempts: null,
  codeAttempts: null,
};

const verifyOtp = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_OTP_UPDATE_CODE:
      return Object.assign({}, state, {
        codeValue: action.codeValue,
      });
    case VERIFY_OTP_UPDATE_SENT_ATTEMPTS:
      return Object.assign({}, state, {
        sentAttempts: action.sentAttempts ?? state.sentAttempts,
        codeAttempts: null,
        codeValue: '',
      });
    case VERIFY_OTP_UPDATE_CODE_ATTEMPTS:
      return Object.assign({}, state, {
        codeAttempts: action.codeAttempts,
        codeValue: '',
      });
    case LOGOUT:
    case VERIFY_OTP_RESET:
      return initialState;
    default:
      return state;
  }
};

export default verifyOtp;
