export const SHOW_LANDING_PAGE = 'SHOW_LANDING_PAGE';
export const getAlaCartePurchaseFlowLink = (
  alaCartePurchaseFlowType,
  isCreditManager,
  previousPage,
) => {
  if (alaCartePurchaseFlowType === SHOW_LANDING_PAGE) {
    return '/alaCartePurchase/landing';
  } else {
    if (isCreditManager) {
      return (
        '/creditReports/view?nav=premiumCreditReports&modal=PREMIUM&previousPage=' +
        previousPage
      );
    }
    return '/creditReports/tabs?nav=premiumCreditReports&modal=PREMIUM';
  }
};
