import {
  build2FAChallenge,
  build2FAVerify,
  build2FAPromote,
  build2FATrustedDevices,
  buildTwoFactorVerifySSN,
  buildDeviceIdCheckChangeEmail,
  buildDeviceIdCheckChangePhone,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';
import { getIovationBlackbox } from 'helpers/common';
import { withSignUpErrorHandling } from './oneClickSignup.service';

export async function post2FAChallenge(mediaType) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: { mediaType },
  });

  return withSignUpErrorHandling(
    standardizedFetchHandler(build2FAChallenge(), requestOptions),
  );
}

export async function putChangeEmailDeviceIdCheck(otp) {
  const blackbox = getIovationBlackbox();
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: { otp, blackbox },
  });
  return standardizedFetchHandler(
    buildDeviceIdCheckChangeEmail(),
    requestOptions,
  );
}

export async function putChangePhoneDeviceIdCheck(otp) {
  const blackbox = getIovationBlackbox();
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: { otp, blackbox },
  });
  return standardizedFetchHandler(
    buildDeviceIdCheckChangePhone(),
    requestOptions,
  );
}

export async function post2FAVerify(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return withSignUpErrorHandling(
    standardizedFetchHandler(build2FAVerify(), requestOptions),
  );
}

export async function put2FAPromote(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });
  return withSignUpErrorHandling(
    standardizedFetchHandler(build2FAPromote(), requestOptions),
  );
}

export async function fetch2FADevices(itemsPerPage = 100) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const params = { itemsPerPage };
  const queryString = convertObjectToQueryString(params);
  return standardizedFetchHandler(
    build2FATrustedDevices() + queryString,
    requestOptions,
  );
}

export async function delete2FADevice(deviceIds) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'DELETE',
  });
  const params = { deviceIds };

  return standardizedFetchHandler(
    build2FATrustedDevices() + convertObjectToQueryString(params),
    requestOptions,
  );
}

export async function postToVerifySsn(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: body,
  });

  return standardizedFetchHandler(buildTwoFactorVerifySSN(), requestOptions);
}
