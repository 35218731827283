import {
  EDIT_EMAIL,
  EDIT_PHONE,
  WELCOME_VERIFICATION,
} from 'pages/AccountVerification/utils/AccountVerification.utils';
import {
  RESET_ACCOUNT_VERIFICATION,
  ACCOUNT_VERIFICATION_OTP_ERROR,
  SET_ACCOUNT_VERIFICATION_DATA,
  SET_ACCOUNT_VERIFICATION_STEP,
  ACCOUNT_VERIFICATION_DUPLICATED_EMAIL,
  ACCOUNT_VERIFICATION_DUPLICATED_PHONE,
} from 'types/accountVerification.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  step: WELCOME_VERIFICATION,
  email: '',
  updatedEmail: '',
  messageError: '',
  emailConfirmed: '',
  duplicatedEmail: '',
  contactPhoneNumber: '',
  updatedContactPhoneNumber: '',
  contactPhoneNumberConfirmed: '',
  duplicatedContactPhoneNumber: '',
  progressBar: { currentStep: 1, stepCount: 3 },
  otpError: false,
};

export default function accountVerificationReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_ACCOUNT_VERIFICATION_DATA: {
      return {
        ...state,
        ...action,
        progressBar: { ...state.progressBar, ...action?.progressBar },
      };
    }
    case ACCOUNT_VERIFICATION_OTP_ERROR:
      return { ...state, otpError: true };
    case SET_ACCOUNT_VERIFICATION_STEP: {
      return { ...state, step: action.step };
    }
    case ACCOUNT_VERIFICATION_DUPLICATED_EMAIL:
      return {
        ...state,
        step: EDIT_EMAIL,
        messageError: null,
        duplicatedEmail: true,
        updatedEmail: action.updatedEmail,
      };
    case ACCOUNT_VERIFICATION_DUPLICATED_PHONE:
      return {
        ...state,
        step: EDIT_PHONE,
        messageError: null,
        duplicatedContactPhoneNumber: true,
        updatedContactPhoneNumber: action.updatedContactPhoneNumber,
      };
    case LOGOUT:
    case RESET_ACCOUNT_VERIFICATION:
      return initialState;
    default:
      return state;
  }
}
