export const adaModalAccessibility = ariaLabel => {
  const modalElement = document.querySelector('div[role="dialog"]');
  if (modalElement) {
    modalElement.setAttribute('aria-label', ariaLabel);

    const hiddenElement = modalElement.querySelector('div[aria-hidden="true"]');

    if (hiddenElement) {
      hiddenElement.setAttribute('aria-hidden', 'false');
      hiddenElement.setAttribute('tabindex', '-1');

      const secondSibling =
        hiddenElement.nextElementSibling?.nextElementSibling;
      if (secondSibling) {
        secondSibling.setAttribute('aria-hidden', 'false');
        secondSibling.setAttribute('tabindex', '-1');
      }
    }
  }
};
