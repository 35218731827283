import { useDecision } from '@optimizely/react-sdk';
import {
  getOptimizelyUniqueIdentifier,
  optimizelyClient,
} from 'externals/_tracking/optimizely/optFlags';
// eslint-disable-next-line no-unused-vars
import { isPreprod2, isLocalPreprod } from 'helpers/env';
import { useEffect } from 'react';

export const useFlag = (flag, opt) => {
  const [decision, ready, didTimeout] = useDecision(flag, { autoUpdate: true });

  const loading = !ready;
  let defaultConfig = opt?.defaultConfig ? opt.defaultConfig : {};

  const forceVariationKey = () => {
    if (opt?.forceVariationKey) {
      const userId = getOptimizelyUniqueIdentifier();
      optimizelyClient.setForcedVariation(
        flag + '_experiment',
        userId,
        opt?.forceVariationKey,
      );
    }
  };

  useEffect(() => {
    forceVariationKey();
    //eslint-disable-next-line
  }, [loading]);

  let config =
    decision.enabled && decision.variables ? decision.variables : defaultConfig;

  //preprod2 is an automated test environment where optimizely should not activate
  //any variations
  const isOptimizelyGloballyDisabled =
    isPreprod2() &&
    !opt?.nonExperimentVariations?.some(x => x === decision?.variationKey);
  const variationKey = isOptimizelyGloballyDisabled
    ? 'off'
    : decision.variationKey;

  //When we the app is over preprod env, variationKey always is off
  //and we could configure a default config
  let forceDefaultConfigForDisabledOptimizely =
    opt?.forceDefaultConfigForDisabledOptimizely;
  if (
    variationKey === 'off' &&
    isOptimizelyGloballyDisabled &&
    forceDefaultConfigForDisabledOptimizely
  ) {
    config = defaultConfig;
  }
  return {
    loading,
    revertToDefault: didTimeout || decision.enabled,
    config,
    isEnabled: decision.enabled,
    flag,
    variationKey,
    forceVariationKey,
  };
};
