import { belongsToPlansForIdProtection, isPlus } from 'helpers/userHelper';

export const useIdProtectionOption = props => {
  const { userInfo } = props || {};
  return {
    config: {
      enabled: isPlus(userInfo) || belongsToPlansForIdProtection(userInfo),
      errors_map: {
        'BU_TU_800.3': 'There was an error, please try later',
        AU_1001: 'It seems your credentials are expired, please login again.',
        SC_1040: "We have detected you don't have permission to do that.",
        '500.0': 'An unexpected system error has occured.',
        'P_A_450.1': 'Please review if you might have wrong or duplicate data.',
        P_A_707: 'We have detected phones duplicated.',
        P_A_708: 'We have detected emails duplicated.',
        P_A_709: 'We have detected medical numbers duplicated.',
      },
    },
  };
};
