import { isNonControlVariationLoadedAndEnabled } from 'helpers/optimizely.helper';
import { useFlag } from 'hook/flag.hook';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { SUBSCRIPTION_VERTICAL } from 'types/mrph.vertical.constants';
import { hideMembershipBenefitsCta } from 'helpers/userHelper';

export const useMembershipBenefitsFlag = props => {
  const { eventHandler, history, userInfo } = props ?? {};
  const membershipBenefitsFlag = useFlag('membership_benefits_web');
  const isMembershipBenefitsFlagEnabled = isNonControlVariationLoadedAndEnabled(
    membershipBenefitsFlag,
  );

  const onClickMembershipBenefitsSettings = () => {
    eventHandler(CLICK, {
      'Click Type': 'Membership Benefits',
      Vertical: SUBSCRIPTION_VERTICAL,
    });
    history.push('/membershipBenefits');
  };

  return {
    onClickMembershipBenefitsSettings,
    membershipBenefitsFlagLoading: membershipBenefitsFlag.loading,
    isMembershipBenefitsEnabled: isMembershipBenefitsFlagEnabled,
    hideMembershipBenefitsButton:
      hideMembershipBenefitsCta(userInfo) || !isMembershipBenefitsFlagEnabled,
  };
};
