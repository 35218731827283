import { isNully, isObject } from 'helpers/objectHelpers';

export function isEmpty(val) {
  return (
    val === null ||
    val === undefined ||
    val === '' ||
    (val && Object.keys(val).length === 0)
  );
}

export const checkMinimumStringLength = (s, minimum = 0) => {
  if (typeof s !== 'string') return false;

  return s.length > minimum;
};

export const isStringAJsonObject = s => {
  try {
    const a = JSON.parse(s);
    return isObject(a);
  } catch (e) {
    return false;
  }
};

export const capitalizeFirstLetter = s => {
  if (typeof s !== 'string') return s;

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const convertToUppercase = s => {
  if (typeof s !== 'string') return s;

  return s.toUpperCase();
};

export const lowerCaseAfterFirstLetter = s => {
  if (typeof s !== 'string') return s;

  return s.charAt(0) + s.slice(1).toLowerCase();
};

export const capitalizeAllFirstLetters = s => {
  if (typeof s !== 'string') return s;
  const capitalizedArrayOfStrings = s.split(' ').map(s => {
    let capitalized = capitalizeFirstLetter(s);
    return lowerCaseAfterFirstLetter(capitalized);
  });
  return capitalizedArrayOfStrings.join(' ');
};

export const hashCode = value => {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    let character = value.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash;
  }
  return Math.abs(hash);
};
export const containsString = (originalString, subString) => {
  if (originalString.toLowerCase().indexOf(subString.toLowerCase()) === -1) {
    return false;
  }
  return true;
};

export const containsStringCaseSensitive = (originalString, subString) => {
  if (originalString.indexOf(subString) === -1) {
    return false;
  }
  return true;
};

export const fixedNumOfLetters = (str, length) => {
  const dots = str && str.length > length ? '...' : '';
  return str.substring(0, length) + dots;
};
export const removeSpaces = str => {
  const regex = /\s/g;
  if (regex.test(str)) {
    str = str.replace(regex, '');
  }
  return str;
};

export const convertNormalTextToCamelCase = s => {
  if (isNully(s) || !checkMinimumStringLength(s, 0)) return '';

  const regex = /\s+(\w)?/gi;

  var output = s.toLowerCase().replace(regex, function (match, letter) {
    return letter?.toUpperCase();
  });

  return output;
};

export const isHtml = str => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
};

export const isSvgFile = filePath => {
  if (!filePath) return false;
  return filePath?.toLowerCase()?.endsWith('.svg');
};

export const makeBoldPartOfTheText = (regex, text) => {
  return text.replace(regex, '<strong>$1</strong>');
};

export function boldPoints(inputString) {
  if (typeof inputString !== 'string') {
    return inputString;
  }
  // Regex to match a number followed by "point" or "points"
  const regex = /(\d+)\s*(point)(s?)/g;

  // Replace the matched text with <b></b> tags around the number and word
  return inputString.replace(regex, (match, number, word, plural) => {
    // Return the number and word (point/points) inside <b></b> tags
    return `<b>${number} ${word}${plural}</b>`;
  });
}
