import React from 'react';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import OText from 'components/CText/OText';
import { Tag } from 'components/Tag';

const PremiumReactivatePendingCancellationMenuListView = props => {
  const {
    collapsed,
    openPremiumReactivatePendingCancellationModal,
    isPremiumReactivatePendingCancellationUser,
    premiumReactivatePendingCancellationConfig,
    daysLeft,
    seenPixel,
  } = props;

  if (collapsed || !isPremiumReactivatePendingCancellationUser) {
    return '';
  } else {
    return (
      <>
        {seenPixel}
        <FlexContainer className="pos-rel">
          <Label className="padL10 m-padL0 padR10">
            <Tag
              className="pos-abs text-10"
              backgroundClass="bgYellow075"
              colorClass="fontNeutral500"
              style={{
                top: -22,
                right: 0,
                padding: '2px 16px',
              }}
            >
              {daysLeft} Days Left
            </Tag>

            <FlexContainer alignItemsCenter className="margTB10">
              <Label className="text-12 system_neutral_n000_text">
                <OText
                  text={
                    premiumReactivatePendingCancellationConfig?.nav_menu?.text
                  }
                />
              </Label>
            </FlexContainer>
            <FlexContainer column alignItemsCenter>
              <Button
                mrphType="primaryLight"
                className="fullWidth"
                onClick={openPremiumReactivatePendingCancellationModal}
              >
                <OText
                  text={
                    premiumReactivatePendingCancellationConfig?.nav_menu?.cta
                  }
                />
              </Button>
            </FlexContainer>
          </Label>
        </FlexContainer>
      </>
    );
  }
};
PremiumReactivatePendingCancellationMenuListView.defaultProps = {
  collapsed: false,
};
export default PremiumReactivatePendingCancellationMenuListView;
