import { createLogger } from 'helpers/logger';
import { getUsrTkn } from 'helpers/localstorage';
import { getCookieValue } from 'helpers/cookie.helper';

const logger = createLogger({
  name: 'tv_scientific.track',
});

export const trackSignupForTvScientific = type => {
  const visitorSessionId = getCookieValue('vid');
  // If no user token, sending Visitor Session ID from mixpanel
  const token = getUsrTkn() || visitorSessionId;
  const src = getCookieValue('src');

  try {
    window.sendPixelToTvScientific(token, type, src);
    logger.info(
      `Sent ${type} pixel to tvScientific for session or user ${token}`,
      {},
    );
  } catch (err) {
    logger.error(err.toString(), {});
  }
};
